import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DataService } from '../../../../_services/data-management/data.service';
import { Resource } from '../../../../models/data/Resource';
import fieldData, { FieldData } from '../../../../models/data/FieldData';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { Router } from '@angular/router';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
})
export class DropdownFieldComponent implements AfterViewInit, DynamicFieldComponent<FieldData<string | string[]> | undefined> {
  @ViewChild('dropDown') dropdown!: ElementRef<HTMLSelectElement>;

  @Input({
    transform: (value: FieldData<string | string[] | unknown>) => fieldData.transform(value),
  })
  data: FieldData<string | string[]> | undefined;
  @Input() choices: Resource[] = [];
  @Input() canAddStructInstance = false;

  @Output() dropdownUpdated = new EventEmitter<string>();

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
  ) {}

  ngAfterViewInit() {
    if (!this.data) throw new Error('Data not found');
    // If the value is already set, set the dropdown to that value. Otherwise, set it to the first value
    if (this.data.value) this.dropdown.nativeElement.value = this.data.value as string;
    else this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.dropdown.nativeElement.value).then();
  }

  update() {
    if (!this.data) throw new Error('Data not found');
    this.data.value = this.dropdown.nativeElement.value;
    this.dropdownUpdated.emit(this.dropdown.nativeElement.value);
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.data.value).then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updated ' + this.data.name + '...', BootstrapClass.INFO);
  }

  async onViewStruct() {
    if (!this.data) return;

    const resource = this.dataService.getResourceStructs().find((resource) => this.data?.fieldType.includes(resource));
    if (!resource) {
      if (this.data.fieldType.includes('Activity')) {
        this.router.navigate([], { queryParams: { activity: this.data.value } }).then();
      }
    }
    if (!resource || !this.data.value.includes('di_')) return;
    await this.router.navigate([`/home/${resource}/${this.data.value}`]);
  }

  async onAddInstanceOfStruct() {
    const structType = this.dataService.getResourceStructs().find((resource) => this.data?.fieldType.includes(resource));
    if (!this.data || !structType) return;

    await this.loadingScreenService.show(async () => {
      try {
        const newStructInstance = await this.dataService.initStruct(structType);
        this.data!.value = newStructInstance.uid;
        await this.dataService.updateFieldValue(this.data!.dataInstanceUid, this.data!.fieldId, newStructInstance.uid);
        await this.onViewStruct();
        this.alertService.showAlert('Created new ' + structType + '...', BootstrapClass.SUCCESS);
      } catch (e) {
        this.alertService.showAlert('Failed to create new ' + structType + '...', BootstrapClass.DANGER);
        throw e;
      }
    });
  }
}
