import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { EnumType } from '../../models/schema/EnumType';
import { StructType } from '../../models/schema/StructType';
import { DataInstance } from '../../models/data/DataInstance';
import { DataPackage } from '../../models/data/DataPackage';
import { Injectable } from '@angular/core';
import { SelectType } from '../../models/schema/SelectType';
import { environment } from '../../../environments/environment';
import { map, Observable, of } from 'rxjs';
import { FileMeta } from '../../models/data/FileMeta';
import { FileMetaCollection } from '../../models/data/FileMetaCollection';
import { FieldEditor } from '../../models/schema/FieldEditor';
import { NO_AUTH } from '../authorization/token.interceptor';
import { Variable } from '../../models/schema/Variable';
import { DataCleanupAnalyzeResult } from '../../models/maintenance/DataCleanupAnalyzeResult';
import { DataCleanupActionRequest } from '../../models/maintenance/DataCleanupActionRequest';
import { DataCleanupResponse } from '../../models/maintenance/DataCleanupResponse';
import { SearchResponse } from '../../models/search/SearchResponse';
import { SearchRequest } from '../../models/search/SearchRequest';
import { NodePosition } from '../../models/schema/NodePosition';
import { Vector2 } from '../../models/types/Vector2';
import { DuplicatedDataInstancesData } from '../../models/data/DuplicatedDataInstancesData';

export interface AuthResponse {
  accessToken: string;
}

interface Schema {
  gameId: string;
  enumTypes: EnumType[];
  structTypes: StructType[];
  selectTypes: SelectType[];
  variables: Variable[];
}

export interface UserView {
  uuid: string;
  username: string;
  email: string;
  roles: string[];
  organizations: SimpleUserOrganizationView[];
}

export interface SimpleUserOrganizationView {
  organizationUuid: string;
  organizationRoles: string[];
}

@Injectable()
export class HTTPRequestService {
  private dbUrl = environment.casHost;

  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    return this.http.post<AuthResponse>(
      environment.authServerHost + 'login/userpass',
      { username, password },
      {
        context: new HttpContext().set(NO_AUTH, true),
      },
    );
  }

  getMe() {
    return this.http
      .get<
        Omit<UserView, 'roles' | 'organizations'> & {
          roles: string;
          organizations: Omit<SimpleUserOrganizationView, 'organizationRoles'> & { organizationRoles: string }[];
        }
      >(environment.authServerHost + 'user/me')
      .pipe(
        map((data) => ({
          ...data,
          roles: data.roles.split(','),
          organizations: data.organizations.map((org) => ({
            ...org,
            organizationRoles: org.organizationRoles.split(','),
          })),
        })),
      );
  }

  isContentEditor() {
    return this.http.get<boolean>(this.buildUrl('/user/me/is-content-editor'));
  }

  logout(): Observable<unknown> {
    // TODO implement
    return of({});
  }

  getSchema(gameId: string) {
    return this.http.get<Schema>(this.buildUrl(`/game/${gameId}/schema`));
  }

  getEnumTypes(gameId: string) {
    return this.http.get<EnumType[]>(this.buildUrl(`/game/${gameId}/schema/enum-types`));
  }

  getStructTypes(gameId: string) {
    return this.http.get<StructType[]>(this.buildUrl(`/game/${gameId}/schema/struct-types`));
  }

  getVariables(gameId: string, dataPackageUid: string) {
    return this.http.get<Variable[]>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/variables`));
  }

  getVariable(gameId: string, dataPackageUid: string, variableRef: string) {
    return this.http.get<Variable>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/variables/${variableRef}`));
  }

  getEnumType(gameId: string, enumUid: string) {
    return this.http.get<EnumType>(this.buildUrl(`/game/${gameId}/schema/enum-types/${enumUid}`));
  }

  getStructType(gameId: string, structUid: string) {
    return this.http.get<StructType>(this.buildUrl(`/game/${gameId}/schema/struct-types/${structUid}`));
  }

  getSelectTypes(gameId: string) {
    return this.http.get<SelectType[]>(this.buildUrl(`/game/${gameId}/schema/select-types`));
  }

  getSelectType(gameId: string, selectUid: string) {
    return this.http.get<SelectType>(this.buildUrl(`/game/${gameId}/schema/select-types/${selectUid}`));
  }

  getFieldEditors(gameId: string) {
    return this.http.get<FieldEditor[]>(this.buildUrl(`/game/${gameId}/schema/field-editors`));
  }

  getDataPackage(gameId: string, dataPackageUid: string) {
    return this.http.get<DataPackage>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}`));
  }

  getDataInstancesOfStruct(gameId: string, dataPackageUid: string, structTypeId: string) {
    return this.http.get<DataInstance[]>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/${structTypeId}`));
  }

  getDataInstance(gameId: string, dataPackageUid: string, structTypeId: string, dataInstanceUid: string) {
    return this.http.get<DataInstance>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/${structTypeId}/${dataInstanceUid}`));
  }

  getNodePosition(gameId: string, dataInstanceUid: string) {
    return this.http.get<NodePosition>(this.buildUrl(`/game/${gameId}/data/node-position/${dataInstanceUid}`));
  }

  getFilesMetaOfDataPackage(gameId: string, dataPackageUid: string) {
    return this.http.get<FileMetaCollection>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}`));
  }

  getFileMetaOfDataInstance(gameId: string, dataPackageUid: string, fileUid: string): Observable<FileMeta> {
    return this.http.get<FileMeta>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}`));
  }

  downloadFile(gameId: string, dataPackageUid: string, fileUid: string) {
    return this.http.get(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}/download`), { responseType: 'blob' });
  }

  getDataInstanceWithChildWithDatatypeAndFieldValue(
    gameId: string,
    dataPackageId: string,
    parentDatatype: string,
    currentDatatype: string,
    fieldValue: string,
  ) {
    return this.http.get<DataInstance>(
      this.buildUrl(
        `/game/${gameId}/search/${dataPackageId}/parent-datatype/${parentDatatype}/current-datatype/${currentDatatype}/fieldValue/${fieldValue}`,
      ),
    );
  }

  postSchemaEnumType(gameId: string, enumType: EnumType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<EnumType>(this.buildUrl(`/game/${gameId}/schema/enum-types`), JSON.stringify(enumType), { headers: headers });
  }

  postSchemaStructType(gameId: string, structType: StructType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<StructType>(this.buildUrl(`/game/${gameId}/schema/struct-types`), JSON.stringify(structType), {
      headers: headers,
    });
  }

  postSchemaSelectType(gameId: string, selectType: SelectType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<SelectType>(this.buildUrl(`/game/${gameId}/schema/select-types`), JSON.stringify(selectType), {
      headers: headers,
    });
  }

  postSchemaVariable(gameId: string, dataPackageUid: string, variable: Variable) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<Variable>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/variables`), JSON.stringify(variable), {
      headers: headers,
    });
  }

  postDataInstance(gameId: string, dataPackageUid: string, structTypeId: string, dataInstance: DataInstance) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<DataInstance>(
      this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/${structTypeId}`),
      JSON.stringify(dataInstance),
      {
        headers: headers,
      },
    );
  }

  uploadFile(gameId: string, dataPackageUid: string, formData: FormData) {
    return this.http.post<FileMeta>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}`), formData);
  }

  updateEnumType(gameId: string, enumType: EnumType, typeId: string, force = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<EnumType>(this.buildUrl(`/game/${gameId}/schema/enum-types/${typeId}`), JSON.stringify(enumType), {
      headers: headers,
      params: { force },
    });
  }

  updateStructType(gameId: string, structType: StructType, typeId: string, force = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<StructType>(this.buildUrl(`/game/${gameId}/schema/struct-types/${typeId}`), JSON.stringify(structType), {
      headers: headers,
      params: { force },
    });
  }

  updateDataInstance(gameId: string, dataPackageUid: string, structTypeId: string, dataInstance: DataInstance) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<DataInstance>(
      this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/${structTypeId}/${dataInstance.uid}`),
      JSON.stringify(dataInstance),
      {
        headers: headers,
      },
    );
  }

  updateFileMeta(gameId: string, dataPackageUid: string, fileUid: string, fileMeta: object) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<FileMeta>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}`), JSON.stringify(fileMeta), {
      headers: headers,
    });
  }

  updateSelectType(gameId: string, selectType: SelectType, typeId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<SelectType>(this.buildUrl(`/game/${gameId}/schema/select-types/${typeId}`), JSON.stringify(selectType), {
      headers: headers,
    });
  }

  updateVariable(gameId: string, dataPackageUid: string, variable: Variable) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<Variable>(
      this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/variables/${variable.variableRef}`),
      JSON.stringify(variable),
      {
        headers: headers,
      },
    );
  }

  updateNodePosition(gameId: string, dataInstanceUid: string, position: Vector2) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<NodePosition>(
      this.buildUrl(`/game/${gameId}/data/node-position/${dataInstanceUid}`),
      {
        dataInstanceUid,
        positionX: position.x,
        positionY: position.y,
      },
      {
        headers: headers,
      },
    );
  }

  replaceFile(gameId: string, dataPackageUid: string, fileUid: string, formData: FormData) {
    return this.http.put<FileMeta>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}`), formData);
  }

  updateFileNameAndAlt(
    gameId: string,
    dataPackageUid: string,
    fileUid: string,
    fileData: {
      name: string;
      alt: string;
    },
  ) {
    return this.http.put<FileMeta>(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}/name`), fileData);
  }

  deleteEnumType(gameId: string, enumTypeId: string, force = false) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/schema/enum-types/${enumTypeId}`), { params: { force } });
  }

  deleteStructType(gameId: string, structTypeId: string, force = false) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/schema/struct-types/${structTypeId}`), { params: { force } });
  }

  deleteVariable(gameId: string, variableRef: string, force = false) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/schema/variables/${variableRef}`), { params: { force } });
  }

  deleteDataInstance(
    gameId: string,
    dataPackageUid: string,
    structTypeId: string,
    dataInstanceUid: string,
    queryParams?: Record<string, string>,
  ) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/${structTypeId}/${dataInstanceUid}`), {
      params: queryParams,
    });
  }

  deleteDataInstances(gameId: string, dataPackageUid: string, dataInstanceUids: string[], queryParams: Record<string, string> = {}) {
    queryParams['dataInstanceUids'] = dataInstanceUids.join(',');
    return this.http.delete(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/data-instances`), {
      params: queryParams,
    });
  }

  duplicateDataInstance(gameId: string, dataPackageUid: string, dataInstanceUids: string[], dropExternalReferencesOfTypes: string[] = []) {
    return this.http.post<DuplicatedDataInstancesData>(this.buildUrl(`/game/${gameId}/data/${dataPackageUid}/duplicate`), {
      instanceUids: dataInstanceUids,
      dropExternalReferencesOfTypes: dropExternalReferencesOfTypes,
    });
  }

  deleteFile(gameId: string, dataPackageUid: string, fileUid: string) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/media/${dataPackageUid}/${fileUid}`));
  }

  deleteSelectType(gameId: string, selectTypeUid: string, force = false) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/schema/select-types/${selectTypeUid}`), { params: { force } });
  }

  deleteNodePosition(gameId: string, dataInstanceUid: string) {
    return this.http.delete(this.buildUrl(`/game/${gameId}/data/node-position/${dataInstanceUid}`));
  }

  analyzeMaintenanceTasks(gameId: string, dataPackageUid: string) {
    return this.http.get<DataCleanupAnalyzeResult>(this.buildUrl(`/maintenance/game/${gameId}/${dataPackageUid}/data-cleanup/analyze`));
  }

  performDataCleanupActions(gameId: string, dataPackageUid: string, actions: DataCleanupActionRequest[]) {
    return this.http.post<DataCleanupResponse>(this.buildUrl(`/maintenance/game/${gameId}/${dataPackageUid}/data-cleanup`), actions);
  }

  clearCache(): Observable<void> {
    return this.http.post(this.buildUrl('/maintenance/clear-cache'), {}).pipe(map(() => {}));
  }

  search(gameId: string, dataPackageUid: string, request: SearchRequest) {
    return this.http.post<SearchResponse>(this.buildUrl(`/game/${gameId}/search/${dataPackageUid}`), request);
  }

  getDataInstanceParent(gameId: string, dataPackageUid: string, dataInstanceUid: string) {
    return this.http.get<{
      parent: DataInstance;
      traversedChildren: DataInstance[];
    }>(this.buildUrl(`/game/${gameId}/search/${dataPackageUid}/datainstance/${dataInstanceUid}/parent`));
  }

  getBackendVersionInformation() {
    return this.http.get<{
      version: { buildNumber: string; commitHash: string; timestamp: string; tag: string };
    }>(this.buildUrl('/version'));
  }

  private buildUrl(path: string) {
    return new URL(this.dbUrl + (path.startsWith('/') ? path : '/' + path)).toString();
  }
}
