import { Component, OnInit } from '@angular/core';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { FieldData } from '../../../../models/data/FieldData';
import { DataService } from '../../../../_services/data-management/data.service';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { SelectorFieldComponent } from '../selector-field/selector-field.component';
import { Router } from '@angular/router';
import { Resource } from '../../../../models/data/Resource';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';
import { NavigationService } from '../../../../_services/navigation.service';

@Component({
  selector: 'app-resource-selector-field',
  template: '<app-selector-field [data]="data" [choices]="choices" [loading]="!choicesLoaded" (reload)="reloadOptionsOfDropdown()" />',
})
export class ResourceSelectorFieldComponent
  extends SelectorFieldComponent
  implements OnInit, DynamicFieldComponent<FieldData<string | string[]> | undefined>
{
  choicesLoaded = false;
  checkedDataInstances: string[] = [];

  constructor(
    dataService: DataService,
    alertService: AlertService,
    router: Router,
    navigationService: NavigationService,
    loadingService: LoadingScreenService,
  ) {
    super(dataService, alertService, router, navigationService, loadingService);
  }

  override async ngOnInit() {
    if (!this.data) return;

    this.choices = [];

    await this.loadingScreenService.show(async () => {
      await this.reloadOptionsOfDropdown(false);

      if (!this.data!.value) {
        if (this.isList) this.data!.value = [];
        else this.data!.value = this.choices[0].value;
      }
    });
  }

  async reloadOptionsOfDropdown(reloadInstanceFromDB = true) {
    if (!this.data) return;

    this.choicesLoaded = false;

    // OnInit of this component we do not want to access the database to get the data instance
    // However, when reloading the dropdown, we do want to because there might have been an update in the meantime
    // Also, when loading too quickly by not accessing the database, the dropdown resets its displayed choice to "Select ..."
    // So we want to give it some loading time to trigger the ngIf choicesLoaded and hide and reload the dropdown component
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid, reloadInstanceFromDB);
    if (!dataInstance) throw new Error('Data instance not found');

    this.field = this.dataService.getField(this.data.fieldId, dataInstance.dataType);
    this.isList = this.field.type.startsWith('List');

    // Get the choices for the dropdown
    const typeId = this.dataService.getTypeIdFromRefType(this.field.type);
    const scope = this.field.fieldEditor?.scope;
    const displayField = this.field.fieldEditor?.displayField;
    this.choices = await this.loadChoices(typeId, scope, displayField, reloadInstanceFromDB);

    this.choicesLoaded = true;
  }

  private async loadChoices(
    typeId: string,
    scope: string | undefined,
    displayField: string | undefined,
    reloadInstancesFromDB: boolean,
  ): Promise<Resource[]> {
    let loadedChoices: Resource[] = [];

    if (!this.data) return [];

    switch (scope) {
      case 'local': {
        this.checkedDataInstances = [];
        const options = await this.searchParentForField(this.data.dataInstanceUid, typeId);

        if (options.length === 0) {
          console.log('No options found for field ' + typeId + ' in parents, so searching globally');
          loadedChoices = await this.getGlobalResources(typeId, reloadInstancesFromDB);
        } else {
          for (const option of options) {
            const instance = await this.dataService.getDataInstance(option);
            if (!instance) {
              loadedChoices.push({ name: option, value: option });
            } else {
              const fieldToDisplay = instance.fieldValues.find((field) => field.field === (displayField ?? 'name'));
              if (!fieldToDisplay) {
                loadedChoices.push({ name: option, value: option });
              } else {
                loadedChoices.push({ name: fieldToDisplay.value as string, value: option });
              }
            }
          }
        }
        break;
      }

      case 'global': {
        loadedChoices = await this.getGlobalResources(typeId, reloadInstancesFromDB);
        break;
      }

      default: {
        // Default (when the field editor scope is not set) is to use the global scope
        loadedChoices = await this.getGlobalResources(typeId, reloadInstancesFromDB);
        break;
      }
    }

    loadedChoices.sort((a, b) => a.name.localeCompare(b.name));
    return loadedChoices;
  }

  // TODO: Use the displayField in case there will ever be anything other than name that needs to be displayed
  private async getGlobalResources(typeId: string, reloadInstancesFromDB: boolean): Promise<Resource[]> {
    const loadedChoices = [];

    if (this.dataService.isStructType(typeId)) {
      if (reloadInstancesFromDB) await this.dataService.loadResourceFromDB(typeId);

      const structResources = this.dataService.getResource(typeId);

      for (const resource of structResources) {
        loadedChoices.push(resource);
      }
    } else if (this.dataService.isEnumType(typeId)) {
      const enumType = this.dataService.getEnumType(typeId);
      for (const struct of enumType.options) {
        const structResources = this.dataService.getResource(struct);

        if (structResources.length === 0) {
          const resourceInstances = await this.dataService.getDataInstancesPerStructType(struct, undefined, undefined, true);
          for (const resource of resourceInstances) {
            // TODO: We need to get a better way of knowing which field is the name field, on top of that some structs
            //  do not have a representative name field. That's why for now we check if the name is undefined and if so
            //  we take the first field as the name field. This is not a good solution.
            const resourceName =
              (resource.fieldValues.find((fieldValue) => fieldValue.field === 'name' || fieldValue.field === 'displayName')?.value as
                | string
                | undefined) ?? (resource.fieldValues[0].value as string);

            structResources.push({
              name: resourceName,
              value: resource.uid,
            });
          }
        }

        for (const resource of structResources) {
          loadedChoices.push(resource);
        }
      }
    }

    return loadedChoices;
  }

  private async searchParentForField(parentUid: string, fieldId: string): Promise<string[]> {
    const parent = await this.dataService.getDataInstance(parentUid);
    if (!parent) {
      console.error('Datainstance not found with uid ' + parentUid);
      return [];
    }
    this.checkedDataInstances.push(parentUid);

    const parentDataType = parent.dataType;
    if (this.dataService.isStructType(parentDataType)) {
      const parentStructType = this.dataService.getStructType(parentDataType);
      const fieldFromParent = parentStructType.fields.find(
        (field) => field.type === 'List<Enum<' + fieldId + '>>' || field.type === 'List<Struct<' + fieldId + '>>',
      );
      if (fieldFromParent) {
        return parent.fieldValues.find((field) => field.field === fieldFromParent?.fieldId)?.value as string[];
      }

      const nextParents = this.dataService.getCurrentDataInstances().filter((dataInstance) => {
        const fieldInNextParent = dataInstance.fieldValues.find((field) => {
          const fieldValue = field.value as string | string[];
          if (Array.isArray(fieldValue)) return fieldValue.indexOf(parentUid) >= 0;
          return fieldValue === parentUid;
        });

        return !!fieldInNextParent;
      });

      for (const nextParent of nextParents) {
        if (this.checkedDataInstances.indexOf(nextParent.uid) >= 0) continue;
        const result = await this.searchParentForField(nextParent.uid, fieldId);
        if (result.length > 0) return result;
      }

      return [];
    } else {
      console.error('Parent with uid ' + parentUid + ' is not a struct');
      return [];
    }
  }
}
