<div *ngIf="data" class="d-flex">
  <select #dropDown (change)="update()" class="form-select" id="dropdown-form" name="dropdown">
    <option *ngFor="let choice of choices" [value]="choice.value">{{ choice.name }}</option>
  </select>
  <button (click)="onViewStruct()" *ngIf="data && data.value.includes('di_')" class="btn btn-primary"><i
    class="bi-box-arrow-up-right"></i></button>
  <button (click)="onAddInstanceOfStruct()" *ngIf="canAddStructInstance"
          class="btn btn-primary">
    <i class="bi-plus-circle"></i>
  </button>
</div>
