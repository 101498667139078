import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../../../_services/data-management/data.service';
import { DataInstance } from '../../../../models/data/DataInstance';
import { Router } from '@angular/router';
import { SelectTypeOption } from '../../../../models/schema/SelectTypeOption';
import { Resource } from '../../../../models/data/Resource';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { VariableType } from '../../../../models/schema/VariableType';
import { Variable } from '../../../../models/schema/Variable';
import { CompareOperator } from '../../../../models/schema/CompareOperator';

@Component({
  selector: 'app-variable-comparison',
  templateUrl: './variable-comparison.component.html',
  styleUrls: ['./variable-comparison.component.scss'],
})
export class VariableComparisonComponent implements OnInit {
  @Input() instance: DataInstance | undefined;
  @Input() showDelete = false;

  @Output() deleteVariableComparison: EventEmitter<void> = new EventEmitter<void>();

  variables1: Variable[] = [];
  variables2: Resource[] = [];

  currentVariable1: Variable | undefined;
  currentVariable2: Variable | undefined;

  variable1 = '';
  variable2 = '';

  allOperators: SelectTypeOption<CompareOperator>[] = [];
  operators: SelectTypeOption<CompareOperator>[] = [];
  operator: SelectTypeOption<CompareOperator> = { optionId: CompareOperator.EqualTo, label: 'EqualTo' };

  compareWithVariable = false;

  value: string | number | boolean = '';

  protected readonly String = String;

  constructor(
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    if (!this.instance) return;

    const variables = await this.dataService.getAllVariables();

    if (variables) {
      this.variables1 = variables;
    }

    this.allOperators = this.dataService.getSelectType('VariableOperator').options as SelectTypeOption<CompareOperator>[];

    if (this.instance.fieldValues) {
      for (const field of this.instance.fieldValues) {
        // The value field cannot be set in here as it needs the variable1 field for its type
        switch (field.field) {
          case 'variable1': {
            this.variable1 = field.value as string;
            this.currentVariable1 = this.variable1 ? await this.dataService.getVariable(this.variable1) : undefined;
            break;
          }
          case 'variable2': {
            this.variable2 = field.value as string;
            break;
          }
          case 'operator': {
            this.operator = field.value as SelectTypeOption<CompareOperator>;
            break;
          }
        }
      }

      if (this.variable1) {
        if (!this.currentVariable1) {
          throw new Error('Variable not found');
        }

        const value = this.instance.fieldValuesMap
          ? this.instance.fieldValuesMap['value']
          : this.instance.fieldValues.find((field) => field.field === 'value');

        if (value) {
          this.value = this.parseVariable(this.currentVariable1!.valueType, value.value as string);
        }

        switch (this.currentVariable1.valueType) {
          case VariableType.String:
            this.operators = this.allOperators.filter((operator) =>
              [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
            );
            break;
          case VariableType.Number:
            this.operators = this.allOperators.filter((operator) =>
              [
                CompareOperator.EqualTo,
                CompareOperator.NotEqualTo,
                CompareOperator.SmallerThan,
                CompareOperator.GreaterThan,
                CompareOperator.SmallerOrEqualTo,
                CompareOperator.GreaterOrEqualTo,
              ].includes(operator.optionId),
            );
            break;
          case VariableType.Boolean:
          case VariableType.Color:
            this.operators = this.allOperators.filter((operator) =>
              [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
            );
            break;
          default:
            // Handle default case if needed
            break;
        }

        this.variables2 = variables
          .filter((variable) => variable.valueType === this.currentVariable1?.valueType)
          .map((variable) => ({
            value: variable.variableRef,
            name: variable.name,
          }));
      }

      // For backwards compatibility
      if (this.variable2 === 'CompareVariableWithValue') {
        this.variable2 = '';
        await this.dataService.updateFieldValue(this.instance?.uid, 'variable2', '');
      }

      if (this.variable2) {
        this.currentVariable2 = await this.dataService.getVariable(this.variable2);
        this.compareWithVariable = true;
        if (!this.currentVariable2) throw new Error('Variable not found');
      }
    }
  }

  async onVariableChange(field: 'variable1' | 'variable2' | 'operator') {
    if (!this.instance) {
      throw new Error('Instance not found');
    }

    switch (field) {
      case 'variable1': {
        const newVariable = await this.dataService.getVariable(this.variable1);
        if (!newVariable) {
          throw new Error('Variable not found');
        }

        await this.dataService.updateFieldValue(this.instance?.uid, field, this.variable1);

        if (newVariable.valueType !== this.currentVariable1?.valueType) {
          switch (newVariable.valueType) {
            case VariableType.String:
              this.operators = this.allOperators.filter((operator) =>
                [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId as CompareOperator),
              );
              break;
            case VariableType.Number:
              this.operators = this.allOperators.filter((operator) =>
                [
                  CompareOperator.EqualTo,
                  CompareOperator.NotEqualTo,
                  CompareOperator.SmallerThan,
                  CompareOperator.GreaterThan,
                  CompareOperator.SmallerOrEqualTo,
                  CompareOperator.GreaterOrEqualTo,
                ].includes(operator.optionId),
              );
              break;
            case VariableType.Boolean:
            case VariableType.Color:
              this.operators = this.allOperators.filter((operator) =>
                [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
              );
              break;
            default:
              // Handle default case if needed
              break;
          }

          this.variables2 = this.variables1
            .filter((variable) => variable.valueType === newVariable.valueType)
            .map((variable) => {
              return { value: variable.variableRef, name: variable.name };
            });

          if (this.compareWithVariable) {
            this.variable2 = '';
            await this.dataService.updateFieldValue(this.instance?.uid, 'variable2', '');
          } else {
            await this.setValueDefault(newVariable.valueType);
          }

          this.operator = { optionId: CompareOperator.EqualTo, label: 'EqualTo' };
          await this.dataService.updateFieldValue(this.instance?.uid, 'operator', '');
        }

        this.currentVariable1 = newVariable;
        break;
      }

      case 'variable2': {
        await this.dataService.updateFieldValue(this.instance?.uid, field, this.variable2);

        const newVariable = await this.dataService.getVariable(this.variable2);
        if (!newVariable) throw new Error('Variable not found');

        this.currentVariable2 = newVariable;
        break;
      }

      case 'operator': {
        await this.dataService.updateFieldValue(this.instance?.uid, field, this.operator);
        break;
      }

      default: {
        throw new Error(`Unknown field '${field}'`);
      }
    }
  }

  async onSwitchCompareWithVariable() {
    if (!this.instance) throw new Error('Instance not found');

    if (this.compareWithVariable) {
      this.variable2 = '';
      this.compareWithVariable = false;
      await this.dataService.updateFieldValue(this.instance?.uid, 'variable2', '');
      await this.setValueDefault(this.currentVariable1!.valueType);
    } else {
      this.value = '';
      this.compareWithVariable = true;
      await this.dataService.updateFieldValue(this.instance?.uid, 'value', '');
    }
  }

  onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    this.router.navigate(['/home/variable/' + variable.variableRef]).then();
  }

  async onAddNewVariable() {
    if (!this.instance) throw new Error('Instance not found');
    const newVariable = await this.dataService.initVariable();
    // We are not setting the fieldValue to the variable ref as it will be removed when you set the type of the variable
    // That removes all refernces to the variable including the one
    this.router.navigate(['/home/variable', newVariable.variableRef]).then();
  }

  onDelete() {
    this.deleteVariableComparison.emit();
  }

  async updateString() {
    if (!this.instance) throw new Error('Instance not found');
    await this.dataService.updateFieldValue(this.instance?.uid, 'value', this.value);
    this.showAlert();
  }

  async updateNumber() {
    if (!this.instance) throw new Error('Instance not found');
    this.value = Math.round(this.value as number);
    await this.dataService.updateFieldValue(this.instance?.uid, 'value', this.value.toString());
    this.showAlert();
  }

  async updateBoolean() {
    if (!this.instance) throw new Error('Instance not found');
    await this.dataService.updateFieldValue(this.instance?.uid, 'value', this.value.toString());
    this.showAlert();
  }

  async updateColor(color: string) {
    if (!this.instance) throw new Error('Instance not found');
    this.value = color;
    await this.dataService.updateFieldValue(this.instance?.uid, 'value', this.value);
    this.showAlert();
  }

  showAlert() {
    if (!this.instance) return;
    this.alertService.showAlert('Updated ' + this.instance.uid + '...', BootstrapClass.INFO);
  }

  private parseVariable(type: VariableType, value: string) {
    switch (type) {
      case VariableType.String:
      case VariableType.Color:
        return value;
      case VariableType.Number:
        return parseFloat(value);
      case VariableType.Boolean:
        return value === 'true';
    }
  }

  private async setValueDefault(variableType: VariableType) {
    if (!this.instance) return;

    switch (variableType) {
      case VariableType.String: {
        this.value = '';
        await this.dataService.updateFieldValue(this.instance?.uid, 'value', '');
        break;
      }
      case VariableType.Number: {
        this.value = 0;
        await this.dataService.updateFieldValue(this.instance?.uid, 'value', '0');
        break;
      }
      case VariableType.Boolean: {
        this.value = false;
        await this.dataService.updateFieldValue(this.instance?.uid, 'value', 'false');
        break;
      }
      case VariableType.Color: {
        this.value = '#00000000';
        await this.dataService.updateFieldValue(this.instance?.uid, 'value', '#00000000');
        break;
      }
    }
  }
}
