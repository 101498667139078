<div class="position-relative">
  <div #rete class="rete" id="#rete"></div>
  <div class="position-absolute bottom-0">
    <div class="bg-body w-auto">
      <button (click)="showLegend = !showLegend" *ngIf="flowchartType === 'mission'" class="btn clickableLegend">
        <i *ngIf="showLegend" class="bi-caret-down-fill"> Hide legend</i>
        <i *ngIf="!showLegend" class="bi-caret-up-fill"> Show legend</i>
      </button>
      <div *ngIf="flowchartType === 'mission' && showLegend" class="ps-1">
        <div *ngFor="let activityType of activityTypes"
             class="pb-1 d-flex">
          <div class="col-1">
            <div [ngStyle]="{'background-color': activityColors[activityType], 'width':'20px', 'height':'20px'}"></div>
          </div>
          <div class="col-8">
            <p class="mx-1 mb-0">{{ activityType }}</p>
          </div>
          <div class="col-3 ms-1">
            <button (click)="createNode(activityType)" [ngStyle]="{'height':'20px'}"
                    class="btn btn-primary btn-sm px-1 py-0 align-content-center">
              <i class="bi-plus-circle"></i> add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute bottom-0 end-0 p-1">
    <div class="d-flex justify-content-end">
      <button (click)="arrangeNodes()" class="btn btn-primary btn-sm ms-1">Arrange</button>
      <button (click)="zoomAtAllNodes()" class="btn btn-primary btn-sm ms-1">Fit to screen</button>
    </div>
    <div *ngIf="flowchartType === 'mission'" class="d-flex my-1">
      <button (click)="copySelectedNodes()"
              [disabled]="!selector || selector.entities.size === 0" class="btn btn-primary btn-sm ms-1">
        Copy selected
      </button>
      <button (click)="duplicateSelectedNodes()"
              [disabled]="!selector || selector.entities.size === 0"
              class="btn btn-primary btn-sm ms-1">
        Duplicate selected
      </button>
      <button (click)="deleteSelectedNodes()"
              [disabled]="!selector || selector.entities.size === 0" class="btn btn-danger btn-sm ms-1">
        Delete selected
      </button>
    </div>
    <div *ngIf="flowchartType === 'mission'" class="d-flex justify-content-end" style="height: 33px; max-width: 500px">
      <input [(ngModel)]="pasteActivityUids" class="form-control form-control-sm ms-1" placeholder="Paste activityUids"
             type="text">
      <button (click)="pasteNodes()" *ngIf="flowchartType === 'mission'" [disabled]="!pasteActivityUids"
              class="btn btn-primary btn-sm ms-1 text-nowrap">
        Paste activities
      </button>
    </div>
  </div>
</div>
