import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../_services/data-management/data.service';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { Field } from '../../../models/schema/Field';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
})
export class ModuleListComponent implements OnInit {
  modules: Record<string, string>[] = [];
  fieldsToDisplay: Field[] = [];
  structTypeDescription = '';

  protected readonly environment = environment;

  constructor(
    private dataService: DataService,
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      await this.dataService.waitForInit();
      await this.getModules();
    });
  }

  openModule(moduleUid: string) {
    this.router.navigate(['/home/module', moduleUid]).then();
  }

  createModule() {
    return this.loadingScreenService.show(async () => {
      const newModule = await this.dataService.initStruct('Module');
      this.openModule(newModule.uid);
    });
  }

  async deleteModule(moduleUid: string, force = false): Promise<unknown> {
    if (!force) {
      const confirmed = await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete this module?'));
      if (!confirmed) return;
    }

    return this.loadingScreenService.show(async () => {
      try {
        const module = await this.dataService.getDataInstanceFromDB(
          this.dataService.currentGameId,
          this.dataService.currentDataPackage,
          'module',
          moduleUid,
        );

        await this.dataService.deleteDataInstance(module, {
          throwError: true,
          force,
        });

        return await this.getModules();
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            'This module is referenced in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.',
          )
        ) {
          return this.deleteModule(moduleUid, true);
        }

        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Resource ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Resource ID!', BootstrapClass.DANGER);
      });
  }

  private async getModules() {
    this.modules = [];

    const modules = await this.dataService.getDataInstancesPerStructType('Module');
    for (const module of modules) {
      const newModule: Record<string, string> = {};
      newModule['moduleUid'] = module.uid;
      module.fieldValues.forEach((fieldValue) => {
        newModule[fieldValue.field] = fieldValue.value as string;
      });
      this.modules.push(newModule);
    }

    this.modules.sort((a, b) => {
      if (!a['name']) return 1;
      if (!b['name']) return -1;
      return a['name'].localeCompare(b['name']);
    });

    const moduleStruct = this.dataService.getStructType('Module');
    this.fieldsToDisplay = moduleStruct.fields
      .filter((field) => field.fieldEditor && field.fieldEditor.showResource);

    this.structTypeDescription = this.dataService.getStructType('Module').description;
  }
}
