import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../_services/data-management/data.service';
import { Variable } from '../../../models/schema/Variable';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { deepCopy } from '../../../_services/utils';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { AlertService } from '../../../_services/UI-elements/alert-service';

@Component({
  selector: 'app-variable-list',
  templateUrl: './variable-list.component.html',
  styleUrls: ['./variable-list.component.scss'],
})
export class VariableListComponent implements OnInit {
  variables: Variable[] = [];
  structTypeDescription = '';

  protected readonly environment = environment;

  constructor(
    private dataService: DataService,
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    this.variables = deepCopy(await this.dataService.getAllVariables()).sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    });
    this.structTypeDescription =
      'Variables are a way to save and use the same data throughout the whole game. For example, when needing the answer of a question in multiple modules, you can use a variable to save the answer in one place and use it in another.';
  }

  openVariable(variable: Variable) {
    this.router.navigate(['/home/variable', variable.variableRef]).then();
  }

  createVariable() {
    return this.loadingScreenService.show(async () => {
      const newVariable = await this.dataService.initVariable();
      this.openVariable(newVariable);
    });
  }

  async deleteVariable(variable: Variable) {
    const confirmed = await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete variable ' + variable.name + '?'));
    if (!confirmed) return;

    return await this.loadingScreenService.show(async () => {
      await this.dataService.deleteVariable(variable, { throwError: true });
      this.variables = this.variables.filter((v) => v.variableRef !== variable.variableRef);
    });
  }

  copyIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('VariableRef copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy VariableRef!', BootstrapClass.DANGER);
      });
  }
}
