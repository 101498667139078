import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../_services/data-management/data.service';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Field } from '../../../models/schema/Field';

@Component({
  selector: 'app-mission-list',
  templateUrl: './mission-list.component.html',
})
export class MissionListComponent implements OnInit {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModal!: NgbModalRef;

  missionInfos: Record<string, string>[] = [];
  fieldsToDisplay: Field[] = [];
  structTypeDescription = '';

  protected newMissionInfo: Record<string, string> = {};
  protected readonly environment = environment;

  constructor(
    private dataService: DataService,
    private router: Router,
    private confirmService: ConfirmationModalService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      await this.dataService.waitForInit();
      await this.getMissionInfos();
    });
  }

  openMission(missionInfoUid: string) {
    this.router.navigate(['/home/mission', missionInfoUid]).then();
  }

  createMission() {
    return this.loadingScreenService.show(async () => {
      const newMissionInfo = await this.dataService.initStruct('MissionInfo');
      this.openMission(newMissionInfo.uid);
    });
  }

  async deleteMission(missionInfoUid: string): Promise<unknown> {
    if (!(await firstValueFrom(this.confirmService.confirm('Are you sure you want to delete this mission?')))) {
      return;
    }

    return await this.loadingScreenService.show(async () => {
      const mission = await this.dataService.getDataInstanceFromDB(
        this.dataService.currentGameId,
        this.dataService.currentDataPackage,
        'mission',
        missionInfoUid,
      );

      if (
        await this.dataService.deleteDataInstance(mission, {
          throwError: true,
        })
      ) {
        return await this.getMissionInfos();
      }
    });
  }

  downloadMission(missionInfoUid: string) {
    return this.loadingScreenService.show(async () => this.dataService.downloadMission(missionInfoUid));
  }

  duplicateMission(missionInfoUid: string) {
    return this.loadingScreenService.show(async () => {
      try {
        const dataInstance = (await this.dataService.duplicateDataInstance([missionInfoUid])).dataInstances[0];

        this.newMissionInfo = {};
        this.newMissionInfo['missionInfoUid'] = dataInstance.uid;
        dataInstance.fieldValues.forEach((fieldValue) => {
          this.newMissionInfo[fieldValue.field] = fieldValue.value as string;
        });
        this.missionInfos.push(this.newMissionInfo);
        this.missionInfos.sort((a, b) => {
          if (!a['name']) return 1;
          if (!b['name']) return -1;
          return a['name'].localeCompare(b['name']);
        });

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModal, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });

        return;
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate mission!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Mission ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Mission ID!', BootstrapClass.DANGER);
      });
  }

  private async getMissionInfos() {
    this.missionInfos = [];
    this.fieldsToDisplay = [];

    const missionInfos = await this.dataService.getMissionInfos();
    for (const missionInfo of missionInfos) {
      const newMissionInfo: Record<string, string> = {};
      newMissionInfo['missionInfoUid'] = missionInfo.uid;
      missionInfo.fieldValues.forEach((fieldValue) => {
        newMissionInfo[fieldValue.field] = fieldValue.value as string;
      });
      this.missionInfos.push(newMissionInfo);
    }

    this.missionInfos.sort((a, b) => {
      if (!a['name']) return 1;
      if (!b['name']) return -1;
      return a['name'].localeCompare(b['name']);
    });

    const missionInfoStruct = this.dataService.getStructType('MissionInfo');
    this.fieldsToDisplay = missionInfoStruct.fields.filter((field) => field.fieldEditor && field.fieldEditor.showResource);

    this.structTypeDescription = missionInfoStruct.description;
  }
}
