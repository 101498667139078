<div *ngIf="isList && data && selectedFieldData">
  <div *ngFor="let value of selectedFieldData.value; let i = index">
    <div class="row">
      <div class="col-10 d-flex">
        <select
          (ngModelChange)="saveUpdates()"
          *ngIf="!loading"
          [(ngModel)]="selectedFieldData.value[i]"
          class="form-select"
          name="dropdownList"
        >
          <option *ngFor="let choice of choices"
                  [value]="choice.value">
            {{ choice.name }}
          </option>
        </select>
        <span *ngIf="loading" class="mx-2">
          <i class="fas fa-refresh fa-spin"></i>
        </span>
        <button (click)="reloadChoices()" [disabled]="loading" class="btn">
          <i class="bi-arrow-repeat"></i>
        </button>

        <button
          (click)="onViewStruct(i)"
          *ngIf="data && data.value[i].includes('di_')"
          class="btn btn-primary mx-1"
        >
          <i class="bi-box-arrow-up-right"></i>
        </button>
        <button (click)="onAddInstanceOfStruct(i)"
                *ngIf="!loading && structType && structType !== 'ClickTarget' && structType !== 'Draggable'"
                class="btn btn-primary">
          <i class="bi-plus-circle"></i>
        </button>
      </div>

      <div class="col-2">
        <button (click)="removeResourceSelector(i)" class="btn btn-danger">
          <span class="bi bi-trash"></span>
        </button>
      </div>
    </div>
  </div>

  <button (click)="addResourceSelector()" *ngIf="isList" class="btn btn-primary">Add</button>
</div>

<div *ngIf="!isList && data && selectedFieldData" class="d-flex">
  <select (ngModelChange)="saveUpdates()" *ngIf="!loading"
          [(ngModel)]="selectedFieldData.value[0]"
          class="form-select" id="nonListDropdown" name="dropdown">
    <option *ngFor="let choice of choices"
            [value]="choice.value">
      {{ choice.name }}
    </option>
  </select>
  <span *ngIf="loading" class="mx-2">
    <i class="fas fa-refresh fa-spin"></i>
  </span>
  <button (click)="reloadChoices()" [disabled]="loading" class="btn">
    <i class="bi-arrow-repeat"></i>
  </button>

  <button
    (click)="onViewStruct(0)"
    *ngIf="data.value[0] && data.value[0].includes('di_')"
    class="btn btn-primary mx-1"
  >
    <i class="bi-box-arrow-up-right"></i>
  </button>

  <button (click)="onAddInstanceOfStruct(0)"
          *ngIf="!loading && structType"
          class="btn btn-primary">
    <i class="bi-plus-circle"></i>
  </button>
</div>
