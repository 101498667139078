import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { accumulate, AreaExtra, createEditor, MySelector } from '../../../rete/defaultEditor';
import { ActivityNode, KennisNode, Schemes } from '../../../rete/nodes';
import { ClassicPreset, NodeEditor } from 'rete';
import { ConnectionData } from '../../../models/data/ConnectionData';
import { DataService } from '../../../_services/data-management/data.service';
import { FlowchartNode } from '../../../models/data/FlowchartNode';
import { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';
import { NodeCategory } from '../../../models/types/NodeCategory';
import { AreaExtensions, AreaPlugin } from 'rete-area-plugin';
import { ExpectedSchemes as ReteSchemes } from 'rete-auto-arrange-plugin/_types/types';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { sleep } from '../../../_services/utils';

@Component({
  selector: 'app-flowchart',
  templateUrl: './flowchart.component.html',
  styleUrls: ['./flowchart.component.scss'],
})
export class FlowchartComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('rete') nodeEditorView!: ElementRef;

  @Input() flowchartType?: string;

  @Output() deleteActivities = new EventEmitter<string[]>();
  @Output() duplicateActivities = new EventEmitter<string[]>();
  @Output() pasteActivities = new EventEmitter<string[]>();
  @Output() addActivity = new EventEmitter<string>();

  factory?: {
    editor: NodeEditor<Schemes>;
    area: AreaPlugin<Schemes, AreaExtra>;
    destroy: () => void;
    selector: MySelector<SelectorEntity>;
    layout: () => void;
    zoomAtAllNodes: () => void;
  };
  area?: AreaPlugin<Schemes, AreaExtra>;
  editor?: NodeEditor<Schemes>;
  selector?: MySelector<SelectorEntity>;
  editorIsClearing = false;
  instanceUidToNodeMap: Map<string, ActivityNode | KennisNode> = new Map<string, ActivityNode | KennisNode>();
  activityTypes: string[] = [];
  activityColors: Record<string, string> = {};
  showLegend = true;
  duplicatingNodes = false;
  deletingNodes = false;
  addingNode = false;
  pasteActivityUids = '';

  private activitiesSubscription?: Subscription;
  private currentActivitySubscription?: Subscription;
  private updateSubscription?: Subscription;
  private nodesSubscription?: Subscription;
  private saveSubscription?: Subscription;
  private nodeSelectedSubscription?: Subscription;
  private nodeUnselectedSubscription?: Subscription;
  private colors = [
    '#FCDF93',
    '#98F5E1',
    '#FF5C5C',
    '#B2E2A2',
    '#FF94E1',
    '#8C7AFF',
    '#FF9178',
    '#B99DF3',
    '#7BC2E5',
    '#FFB649',
    '#CD64FF',
  ];
  private nodePositions: Record<string, { x: number; y: number }> = {};

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private loadingScreenService: LoadingScreenService,
    private confirmService: ConfirmationModalService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    // If the flowchart is for a mission, we have different subscriptions.
    // Because we need to update the connections manually when there are changes in the data.
    switch (this.flowchartType) {
      case 'mission':
        this.initializeMissionSubscriptions().then();
        break;
      case 'module':
        this.initializeModuleSubscriptions();
        break;
      default:
        console.warn('Flowchart type: ' + this.flowchartType + ' is not supported yet');
        break;
    }

    this.currentActivitySubscription = this.dataService.currentActivityChanged$.subscribe((node: FlowchartNode | undefined) => {
      if (!this.selector || !this.editor || !this.area) return console.warn("Selector, editor, or area doesn't exist");
      this.editor.getNodes().forEach((n) => (n.selected = false));

      if (node) {
        const nextNode = this.getNodeByInstanceUid(node.dataInstanceUid);
        const area = this.area;
        if (nextNode) {
          this.selector.pick({ id: nextNode.id, label: nextNode.label });
          this.selector.add(
            {
              label: 'node',
              id: nextNode.id,
              translate(dx, dy) {
                const view = area.nodeViews.get(nextNode.id);
                const current = view?.position;

                if (current) {
                  void view.translate(current.x + dx, current.y + dy);
                }
              },
              unselect() {
                if (nextNode.selected) {
                  nextNode.selected = false;
                  void area.update('node', nextNode.id);
                }
              },
            },
            accumulate().active(),
          );
          nextNode.selected = true;
          this.area.update('node', nextNode.id);
        }
      } else {
        this.selector.unselectAll(true);
      }
    });

    await this.loadingScreenService.show(async () => {
      await this.dataService.waitForInit();
      this.activityTypes = this.dataService.getEnumType('Activity').options;
      for (let i = 0; i < this.activityTypes.length; i++) {
        this.activityColors[this.activityTypes[i]] = this.colors[i % this.colors.length];
      }
    });
  }

  ngOnDestroy() {
    this.activitiesSubscription?.unsubscribe();
    this.updateSubscription?.unsubscribe();
    this.nodesSubscription?.unsubscribe();
    this.currentActivitySubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.nodeSelectedSubscription?.unsubscribe();
    this.nodeUnselectedSubscription?.unsubscribe();
  }

  async ngAfterViewInit() {
    const editorElement = this.nodeEditorView.nativeElement;

    if (!editorElement) {
      console.warn('Node editor view not found');
      return;
    }

    this.factory = await createEditor(editorElement, this.injector, this.flowchartType === 'module');
    this.area = this.factory.area;
    this.editor = this.factory.editor;
    this.selector = this.factory.selector;

    AreaExtensions.snapGrid(this.area, {
      size: 16,
    });

    this.nodeSelectedSubscription = this.selector.nodeSelected$.subscribe(async (nodeEntity) => {
      if (!nodeEntity || !this.editor) {
        return console.warn(`Node entity or editor not found`);
      }

      const selectedNode = this.editor.getNode(nodeEntity.id);
      const nodeInstance = await this.dataService.getDataInstance(selectedNode.uid);
      if (nodeInstance) {
        this.editFlowchartNode({
          dataInstanceUid: nodeInstance.uid,
          name: selectedNode.label,
          type: nodeInstance.dataType,
          fieldValues: nodeInstance.fieldValues,
          nodeCategory: NodeCategory.Activity,
        });
      }
    });

    this.nodeUnselectedSubscription = this.selector.nodeUnselected$.subscribe(async (nodeEntity) => {
      if (!nodeEntity || !this.editor) {
        return console.warn(`Node entity not found`);
      }
      // We need to wait for the build-in select from Rete to finish so we can unselect the node ourselves
      await sleep(100);
      const selectedNode = this.editor.getNode(nodeEntity.id);
      selectedNode.selected = false;
    });

    this.editor.addPipe((context) => {
      switch (context.type) {
        case 'connectioncreated': {
          const connection = context.data;
          // If the flowchart is for a module, we need to update the connections in the data service
          if (this.flowchartType === 'module') {
            this.onAddedConnectionForModuleFlowChart(connection).then();
          }
          break;
        }
        case 'connectionremoved': {
          const removedConnection = context.data;
          // If the flowchart is for a module, we need to update the connections in the data service
          if (this.flowchartType === 'module') {
            this.onDeletedConnectionForModuleFlowChart(removedConnection).then();
          }
          break;
        }
        default:
          break;
      }

      return context;
    });

    this.area.addPipe(async (context) => {
      if (context.type === 'nodedragged') {
        await this.updatePositions();
      }
      return context;
    });
  }

  // Arrange the nodes in the editor
  arrangeNodes() {
    if (!this.factory) return console.warn('Factory not found, cannot arrange nodes');
    this.factory.layout();
  }

  zoomAtAllNodes() {
    if (!this.factory) return console.warn('Factory not found, cannot zoom at all nodes');
    this.factory.zoomAtAllNodes();
  }

  async deleteSelectedNodes() {
    if (!this.editor) return console.warn('Editor not found, cannot delete selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return console.warn('No nodes selected');
    }

    let confirmed: boolean;
    let nodeNames = '';
    Array.from(this.selector.entities.values()).forEach((nodeSelector) => {
      const node = this.editor!.getNode(nodeSelector.id);
      nodeNames += node ? node.label + ', ' : '';
    });
    if (nodeNames.length > 2) nodeNames = nodeNames.slice(0, nodeNames.length - 2);
    if (this.selector.entities.size === 1) {
      confirmed = await firstValueFrom(
        this.confirmService.confirm(
          'Are you sure you want to delete this activity? This action cannot be undone and will delete all references to this activity.',
        ),
      );
    } else {
      confirmed = await firstValueFrom(
        this.confirmService.confirm(
          'Are you sure you want to delete these activities: ' +
            nodeNames +
            '? This action cannot be undone and will delete all references to these activities.',
        ),
      );
    }
    if (!confirmed) return;

    const activitiesToDelete: string[] = [];
    for (const nodeSelector of this.selector!.entities.values()) {
      const node = this.editor!.getNode(nodeSelector.id);
      if (!node) return console.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToDelete.push(node.uid);
    }

    this.deletingNodes = true;
    this.deleteActivities.emit(activitiesToDelete);
    this.selector.unselectAll(true);
  }

  async duplicateSelectedNodes() {
    if (!this.editor) return console.warn('Editor not found, cannot duplicate selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return console.warn('No nodes selected');
    }

    const activitiesToDuplicate: string[] = [];
    for (const nodeSelector of this.selector.entities.values()) {
      const node = this.editor.getNode(nodeSelector.id);
      if (!node) return console.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToDuplicate.push(node.uid);
    }

    this.duplicatingNodes = true;
    this.duplicateActivities.emit(activitiesToDuplicate);
    this.selector.unselectAll(true);
  }

  copySelectedNodes() {
    if (!this.editor) return console.warn('Editor not found, cannot copy selected nodes');
    if (!this.selector || this.selector.entities.size === 0) {
      this.alertService.error('No nodes selected');
      return console.warn('No nodes selected');
    }

    const activitiesToCopy: string[] = [];
    for (const nodeSelector of this.selector.entities.values()) {
      const node = this.editor.getNode(nodeSelector.id);
      if (!node) return console.warn('Node not found with id: ' + nodeSelector.id);
      activitiesToCopy.push(node.uid);
    }

    navigator.clipboard
      .writeText(activitiesToCopy.join(','))
      .then(() => {
        this.alertService.showAlert(`Successfully copied activities to clipboard`, BootstrapClass.SUCCESS);
      })
      .catch((error) => {
        throw new Error('Failed to copy activities to clipboard: ' + error);
      });
  }

  async pasteNodes() {
    if (!this.editor || !this.selector) return console.warn('Editor or selector not found, cannot paste nodes');
    if (!this.pasteActivityUids) {
      this.alertService.error('No activities to paste');
      return console.warn('No activities to paste');
    }

    const activitiesToPaste = this.pasteActivityUids.split(',');
    const activityTypes = this.dataService.getEnumType('Activity').options;
    try {
      for (const activityUid of activitiesToPaste) {
        const activity = await this.dataService.getDataInstance(activityUid);
        if (!activity) return console.warn('Activity not found with uid: ' + activityUid);
        if (!activityTypes.includes(activity.dataType)) return this.alertService.error('Cannot paste non-activity dataInstances');
      }

      this.duplicatingNodes = true;
      this.pasteActivities.emit(activitiesToPaste);
      this.selector.unselectAll(true);
      this.pasteActivityUids = '';
    } catch (error) {
      this.alertService.error('Failed to paste activities');
      return console.warn('Failed to paste activities: ' + error);
    }
  }

  createNode(activityType: string) {
    this.addActivity.emit(activityType);
  }

  getNodeByInstanceUid(instanceUid: string) {
    if (!this.editor) return console.warn('Editor not found, cannot get node');
    return this.editor.getNode(this.instanceUidToNodeMap.get(instanceUid)?.id ?? '');
  }

  editFlowchartNode(node: FlowchartNode) {
    this.dataService.editNode(node);

    // Save when we move to another activity
    this.dataService.saveButtonCalled();
  }

  async addNode(flowchartNode: FlowchartNode) {
    if (!this.editor) return console.warn('There is no editor defined');
    if (!flowchartNode) return console.warn('There is no flowchart node to add');

    // Create the node
    let node: ActivityNode | KennisNode;

    switch (flowchartNode.nodeCategory) {
      case NodeCategory.Activity:
        node = new ActivityNode(
          flowchartNode.name,
          flowchartNode.type,
          flowchartNode.dataInstanceUid,
          this.activityColors[flowchartNode.type],
        );
        break;
      case NodeCategory.Kennis:
        node = new KennisNode(flowchartNode.name, flowchartNode.type, flowchartNode.dataInstanceUid);
        break;
      default:
        return console.warn(`Category: ${flowchartNode.nodeCategory} is not supported yet`);
    }

    // Add the node to the editor
    const isNodeAdded = await this.editor.addNode(node);
    if (!isNodeAdded) {
      return console.warn('Node not added');
    }

    if (flowchartNode.position) {
      const { x, y, k } = this.area!.area.transform;
      const box = this.area!.container.getBoundingClientRect();
      const halfWidth = box.width / 2;
      const halfHeight = box.height / 2;

      let newNode = false;
      if (flowchartNode.position.x === 0) {
        flowchartNode.position.x = halfWidth - x / k;
        newNode = true;
        this.addingNode = true;
      }
      if (flowchartNode.position.y === 0) {
        flowchartNode.position.y = halfHeight - y / k;
        newNode = true;
        this.addingNode = true;
      }

      if (newNode && this.flowchartType === 'mission')
        await this.dataService.saveNodePosition(flowchartNode.dataInstanceUid, flowchartNode.position);
    }

    if (flowchartNode.position && this.area) {
      this.nodePositions[node.id] = { x: flowchartNode.position.x, y: flowchartNode.position.y };
      await this.area.translate(node.id, { x: flowchartNode.position.x, y: flowchartNode.position.y });
    }

    this.instanceUidToNodeMap.set(flowchartNode.dataInstanceUid, node);
  }

  async onAddedConnectionForModuleFlowChart(connection: ReteSchemes['Connection']) {
    if (!connection.sourceOutput) {
      return console.warn(`No source output for connection: ${connection.id}`);
    }

    const sourceNode = await this.dataService.getDataInstance(connection.sourceOutput);
    if (!sourceNode) {
      throw new Error('Could not find source node');
    }

    const outgoingConnections = sourceNode.fieldValues.find((fieldValue) => fieldValue.field === 'outgoingConnections');
    if (!outgoingConnections || !Array.isArray(outgoingConnections.value)) {
      throw new Error('No outgoing connections found');
    }

    // Check if the connection is already in the outgoing connections, if not, add it
    if (!outgoingConnections.value.includes(connection.targetInput)) {
      outgoingConnections.value.push(connection.targetInput);
      await this.dataService.updateFieldValue(sourceNode.uid, outgoingConnections.field, outgoingConnections.value);
    }
  }

  // Deletes the connection from the data, when the connection is deleted in the editor
  async onDeletedConnectionForModuleFlowChart(connection: ReteSchemes['Connection']) {
    // If the editor is clearing, we don't need to delete the connection in the data
    if (this.editorIsClearing || !connection.sourceOutput) {
      return;
    }

    const sourceNode = await this.dataService.getDataInstance(connection.sourceOutput);
    if (!sourceNode) {
      throw new Error('Could not find source node');
    }

    const outgoingConnections = sourceNode.fieldValues.find((fieldValue) => fieldValue.field === 'outgoingConnections');
    if (!outgoingConnections || !Array.isArray(outgoingConnections.value)) {
      throw new Error('No outgoing connections found');
    }

    // Check if the connection is in the outgoing connections, if so, remove it
    const index = outgoingConnections.value.findIndex((value: string) => value === connection.targetInput);
    if (index !== -1) {
      outgoingConnections.value.splice(index, 1);
      await this.dataService.updateFieldValue(sourceNode.uid, outgoingConnections.field, outgoingConnections.value);
    }
  }

  // Update all connections as currently saved in the data service
  async updateConnectionsForMissionFlowChart() {
    if (!this.editor) {
      return console.warn('Editor not found, cannot update connections');
    }

    const savedConnections: ConnectionData[] = [];

    // Go over all data instances. If the instance has an activityChange field, add the connection(s).
    for (const dataInstance of this.dataService.getCurrentDataInstances()) {
      for (const field of dataInstance.fieldValues) {
        if (field.field != 'activityChange') {
          continue;
        }

        if (!field.value) {
          console.warn('Activity change has no value');
          continue;
        }

        // Get the activity change data instance
        const activityChange = await this.dataService.getDataInstance(field.value as string);
        if (!activityChange) throw new Error('Activity change data instance not found');

        for (const activityChangeField of activityChange.fieldValues) {
          // If the field has a next activity as value, fieldId is 'nextActivity' or 'NextActivity', add the connection.
          if (activityChangeField.field.endsWith('ctivity') && activityChangeField.value) {
            savedConnections.push({
              sourceUid: dataInstance.uid,
              targetUid: activityChangeField.value as string,
            });

            continue;
          }

          switch (activityChangeField.field) {
            case 'options': {
              const options = Array.isArray(activityChangeField.value)
                ? activityChangeField.value
                : (activityChangeField.value as string).startsWith('[')
                  ? JSON.parse(activityChangeField.value as string)
                  : [activityChangeField.value];

              for (const option of options) {
                const instance = await this.dataService.getDataInstance(option);
                if (!instance) {
                  console.warn('Option not found');
                  continue;
                }

                for (const optionField of instance.fieldValues) {
                  if (optionField.field.endsWith('extActivity') && optionField.value) {
                    savedConnections.push({
                      sourceUid: dataInstance.uid,
                      targetUid: optionField.value as string,
                    });
                  }
                }
              }
              break;
            }

            case 'conditions': {
              for (const condition of activityChangeField.value as string[]) {
                const instance = await this.dataService.getDataInstance(condition);
                if (!instance) {
                  console.warn('Conditions not found');
                  continue;
                }

                for (const conditionField of instance.fieldValues) {
                  if (conditionField.field === 'activity' && conditionField.value) {
                    savedConnections.push({
                      sourceUid: dataInstance.uid,
                      targetUid: conditionField.value as string,
                    });
                  }
                }
              }
              break;
            }

            case 'requestCases': {
              for (const condition of activityChangeField.value as string[]) {
                const instance = await this.dataService.getDataInstance(condition);
                if (!instance) {
                  console.warn('Request cases not found');
                  continue;
                }

                for (const conditionField of instance.fieldValues) {
                  if (conditionField.field === 'nextActivity' && conditionField.value) {
                    savedConnections.push({
                      sourceUid: dataInstance.uid,
                      targetUid: conditionField.value as string,
                    });
                  }
                }
              }
              break;
            }
          }
        }
      }
    }

    const connectionsToCheck = [...savedConnections];
    const connectionsInEditor = this.editor.getConnections();

    for (const connection of connectionsInEditor) {
      // If this connection is in connectionsToCheck, then keep the connection, but delete it from connectionsToCheck
      const index = connectionsToCheck.findIndex(
        (savedConnection) => savedConnection.sourceUid === connection.sourceOutput && savedConnection.targetUid === connection.targetInput,
      );

      if (index !== -1) {
        connectionsToCheck.splice(index, 1);
        continue;
      }

      // Else delete the connection
      if (this.editor) await this.editor.removeConnection(connection.id);
    }

    // For each connection left in connectionsToCheck, add the connection to the editor.
    for (const connection of connectionsToCheck) {
      const sourceNode = this.getNodeByInstanceUid(connection.sourceUid);
      const targetNode = this.getNodeByInstanceUid(connection.targetUid);

      if (!sourceNode || !targetNode) {
        console.warn('No source- or targetnode for connection:', connection);
        return;
      }

      await this.editor.addConnection(new ClassicPreset.Connection(sourceNode, sourceNode.uid, targetNode, targetNode.uid));
    }
  }

  private initializeModuleSubscriptions() {
    this.nodesSubscription = this.dataService.nodesUpdated$.subscribe(async (nodes) => await this.updateEditor(nodes));
  }

  private async initializeMissionSubscriptions() {
    // Get the activity change enum
    await this.dataService.waitForInit();
    const activityChangeEnum = this.dataService.getEnumType('ActivityChange');

    // When the data is saved, we need to update the editor
    this.updateSubscription = this.dataService.instanceUpdated$.subscribe((instanceObject) => {
      if (
        instanceObject &&
        this.editor &&
        // TODO: Find a better way to check if we need to update the connections
        // Check if the instance has an activityChange field or is an ActivityChange/multipleChoiceOption instance
        (instanceObject.fieldValues.find((fieldValue) => fieldValue.field === 'activityChange') ||
          activityChangeEnum.options.includes(instanceObject.dataType) ||
          instanceObject.dataType === 'MultipleChoiceOption' ||
          instanceObject.dataType === 'ConditionWithNextActivity' ||
          instanceObject.dataType === 'RequestItemActivityChange')
      )
        this.updateConnectionsForMissionFlowChart().then();
    });
    // When the activities are updated, we need to update the editor
    this.activitiesSubscription = this.dataService.activitiesUpdated$.subscribe(async (activities: FlowchartNode[]) => {
      await this.updateEditor(activities);
      // Await for the editor to have added all activities
      await this.updateConnectionsForMissionFlowChart();

      if (activities.length > 1 && activities.every((node) => !node.position || (node.position.x === 0 && node.position.y === 0))) {
        // Nodes are all at 0;0, let's auto-arrange
        this.arrangeNodes();
      }
    });
  }

  // Update the positions of the nodes in the data service

  private async updateEditor(flowchartNodes: FlowchartNode[]) {
    if (!this.editor) {
      console.warn('Editor not defined');
      return;
    }

    // We need to clear the editor before we can add new nodes
    // We need to set the editorIsClearing flag, because otherwise onRemovedConnectionForMissionFlowChart will be called
    this.editorIsClearing = true;
    const res = await this.editor.clear();
    if (!res) {
      console.warn('Editor not cleared');
      return;
    }

    this.instanceUidToNodeMap.clear();
    await Promise.all(flowchartNodes.map(async (node) => this.addNode(node)));

    // If the flowchart is for a module, we need to create the connections in the data service
    if (this.flowchartType === 'module') this.createConnectionsForModuleFlowChart(flowchartNodes);
    if (!this.duplicatingNodes && !this.deletingNodes && !this.addingNode) this.zoomAtAllNodes();

    this.duplicatingNodes = false;
    this.deletingNodes = false;
    this.addingNode = false;
    this.editorIsClearing = false;
  }

  private createConnectionsForModuleFlowChart(flowchartNodes: FlowchartNode[]) {
    if (!this.editor) {
      console.warn('Editor not defined');
      return;
    }

    for (const node of flowchartNodes) {
      const outgoingConnections = node.fieldValues.find((fieldValue) => fieldValue.field === 'outgoingConnections');
      if (!outgoingConnections) throw new Error('No outgoing connections found');

      if (typeof outgoingConnections.value === 'string') {
        outgoingConnections.value = JSON.parse(outgoingConnections.value);
      }

      for (const targetUid of outgoingConnections.value as string[]) {
        const sourceNode = this.getNodeByInstanceUid(node.dataInstanceUid);
        const targetNode = this.getNodeByInstanceUid(targetUid);

        if (!sourceNode || !targetNode) {
          console.warn('No source- or targetnode for connection:', node);
          continue;
        }

        this.editor?.addConnection(new ClassicPreset.Connection(sourceNode, sourceNode.uid, targetNode, targetNode.uid));
      }
    }
  }

  private async updatePositions() {
    if (!this.editor) {
      console.warn('Editor not defined');
      return;
    }

    const nodes: Schemes['Node'][] = this.editor.getNodes();

    for (const node of nodes) {
      const nodePosition = this.area?.nodeViews.get(node.id)?.position;
      if (!nodePosition) throw new Error('No node position found');
      if (this.nodePositions[node.id].x === nodePosition.x && this.nodePositions[node.id].y === nodePosition.y) continue;

      this.nodePositions[node.id] = nodePosition;

      if (this.flowchartType === 'module') {
        const KennisNode = await this.dataService.getDataInstance(node.uid);
        if (!KennisNode) throw new Error('No KennisNode with uid: ' + node.uid + ' found, but it is in the editor');

        const position = KennisNode.fieldValues.find((fieldValue) => fieldValue.field === 'position');
        if (!position) throw new Error('No position field found');

        // We need to flip the value because Unity uses a different coordinate system and needs these coordinates as well
        position.value = { x: nodePosition.x, y: -nodePosition.y };
        await this.dataService.updateFieldValue(KennisNode.uid, position.field, position.value);
      } else {
        await this.dataService.saveNodePosition(node.uid, nodePosition);
      }
    }
  }
}
