<div *ngIf="variable" class="card">
  <div class="card-header lb-card-header">
    Variable: {{ variable.variableRef }}
  </div>

  <div class="d-flex justify-content-between mb-2">
    <div class="label">Name</div>

    <input
      #name="ngModel"
      (change)="hasChanges = true"
      [(ngModel)]="newName"
      class="form-control"
      id="name"
      name="name"
      required
      type="text"
    />

    <div *ngIf="name.invalid && name.touched" class="alert alert-danger">
      Name is required.
    </div>
  </div>

  <div class="d-flex justify-content-between mb-2">
    <div class="label">Description</div>
    <input (change)="hasChanges = true" [(ngModel)]="newDescription" class="form-control" type="text"/>
  </div>

  <div class="d-flex justify-content-between mb-2">
    <div class="label">Value type</div>

    <select (change)="onValueTypeChange()" [(ngModel)]="newValueType" class="form-select">
      <option *ngFor="let option of valueTypeOptions">
        {{ option }}
      </option>
    </select>
  </div>

  <div *ngIf="newValueType" class="d-flex justify-content-between mb-2">
    <div class="label">Start Value</div>

    <input
      (change)="hasChanges = true"
      *ngIf="newValueType === VariableType.String"
      [(ngModel)]="newStartValue"
      class="form-control" type="text"
    />

    <input
      (change)="onNewNumber()"
      *ngIf="newValueType === VariableType.Number"
      [(ngModel)]="newStartValue"
      class="form-control"
      type="number"
    />

    <div *ngIf="newValueType === VariableType.Boolean" class="form-check form-switch w-100">
      <input
        (change)="hasChanges = true"
        [(ngModel)]="newStartValue"
        class="form-check-input" role="switch" type="checkbox"
      />
    </div>

    <div *ngIf="newValueType === VariableType.Color" class="form-check form-switch w-100">
      <input
        (colorPickerSelect)="updateColor($event)"
        [colorPicker]="String(newStartValue)"
        [cpAlphaChannel]="'always'"
        [cpOKButtonText]="'Save'"
        [cpOKButton]="true"
        [cpOutputFormat]="'hex'"
        [style.background]="newStartValue"
        [value]="newStartValue"
        style="width: 100px"
      />
    </div>
  </div>
</div>

<button (click)="saveVariable()" [disabled]="!hasChanges" class="btn btn-primary">
  <i class="far fa-save"></i>
  Save
</button>

<button (click)="deleteVariable()" class="btn btn-danger">
  <i class="bi bi-trash"></i>
  Delete
</button>
