import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DataService } from '../../../../_services/data-management/data.service';
import { FileMeta } from '../../../../models/data/FileMeta';
import { FieldData } from '../../../../models/data/FieldData';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { HTTPRequestService } from '../../../../_services/data-management/HTTP-request.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, DynamicFieldComponent<FieldData<string> | undefined> {
  @Input() fileType: string | undefined;
  @Input() data: FieldData<string> | undefined;
  @Input() currentURL = '';

  @Output() newFileSelected = new EventEmitter<{ name: string; url: string; mimeType: string }>();

  currentFileMeta: FileMeta | undefined;
  fileName = '';
  file: File | undefined;

  loadedFiles: FileMeta[] = [];
  searchFiles: FileMeta[] = [];
  loadingFiles = true;

  replaceFileUid?: string;
  uploadFileName = '';
  uploadFileAlt = '';

  uploading = false;
  editingName = false;

  searchTerm = '';

  fileSelectModalRef: NgbModalRef | undefined;

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private httpRequestService: HTTPRequestService,
  ) {}

  search(event?: string) {
    const query = event ?? this.searchTerm;
    if (!query.length) {
      this.searchFiles = this.loadedFiles;
      return;
    }

    this.searchFiles = this.loadedFiles.filter((f) => f.name.toLowerCase().includes(query.toLowerCase().trim()));
  }

  async prepareUpload() {
    await this.loadMedia();
    this.file = undefined;
    this.replaceFileUid = undefined;
    this.fileName = '';
  }

  async prepareReplace() {
    await this.loadMedia();
    if (!this.data) throw new Error('Data not found');

    this.file = undefined;
    this.replaceFileUid = this.data.value;
    this.fileName = this.loadedFiles.find((f) => f.uid === this.data?.value)?.name || '';
  }

  async ngOnInit() {
    if (this.data && this.data.value) await this.selectFile(this.data.value);
  }

  update() {
    if (!this.data) throw new Error('Data not found');
    this.dataService.updateFieldValue(this.data.dataInstanceUid, this.data.fieldId, this.currentFileMeta?.uid || '').then();
    this.showAlert();
  }

  showAlert() {
    if (!this.data) return;
    this.alertService.showAlert('Updated ' + this.data.name + '...', BootstrapClass.INFO);
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.uploadFileName = this.file.name;
    }
  }

  async onUpdateFileName() {
    if (!this.data) throw new Error('Data not found');
    this.replaceFileUid = this.data.value;

    try {
      this.uploading = true;
      this.currentFileMeta = await this.dataService.updateFileNameAndAlt(this.replaceFileUid, this.uploadFileName, this.uploadFileAlt);
      await this.loadMedia();
      return;
    } finally {
      this.uploading = false;
      this.editingName = false;
    }
  }

  openSelectFileModal(content: TemplateRef<unknown>) {
    this.fileSelectModalRef = this.openModal(content, 'full-width-modal');
  }

  openModal(content: TemplateRef<unknown>, modalDialogClass?: string): NgbModalRef {
    this.modalService.dismissAll('Closed before opening new modal');
    return this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title', modalDialogClass });
  }

  async submitFileUpload(modal: NgbModalRef) {
    if (!this.file) {
      if (!this.replaceFileUid) {
        console.warn('No file selected');
        return;
      }

      if (!this.uploadFileName) {
        window.alert('Please enter a name for the file');
        return;
      }

      try {
        this.uploading = true;
        this.currentFileMeta = await this.dataService.updateFileNameAndAlt(this.replaceFileUid, this.uploadFileName, this.uploadFileAlt);
        await this.loadMedia();
        modal.dismiss('Name changed');
        return;
      } finally {
        this.uploading = false;
      }
    }

    try {
      this.uploading = true;

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('name', this.uploadFileName);
      formData.append('alt', this.uploadFileAlt);

      if (this.replaceFileUid) {
        this.currentFileMeta = await this.dataService.replaceFile(this.replaceFileUid, formData);
      } else {
        this.currentFileMeta = await this.dataService.uploadFile(formData);
      }
      this.newFileSelected.next({
        name: this.file.name,
        url: URL.createObjectURL(this.file),
        mimeType: this.file.type,
      });

      await this.loadMedia();
      this.update();
      modal.dismiss('File uploaded');
    } finally {
      this.uploading = false;
    }
  }

  async loadMedia() {
    this.loadingFiles = true;
    const allFiles = await this.dataService.getFilesMeta(this.dataService.currentDataPackage);

    if (!this.fileType) {
      this.loadedFiles = allFiles;
    }

    if (this.fileType === 'video') {
      this.loadedFiles = allFiles.filter((file) => file.fileType.startsWith('video'));
    }

    if (this.fileType === 'audio') {
      this.loadedFiles = allFiles.filter((file) => file.fileType.startsWith('audio'));
    }

    if (this.fileType === 'image') {
      this.loadedFiles = allFiles.filter((file) => file.fileType.includes('image'));
    }

    this.search();
    this.loadingFiles = false;
  }

  async selectFile(fileUid: string) {
    this.currentFileMeta = await lastValueFrom(
      this.httpRequestService.getFileMetaOfDataInstance(this.dataService.currentGameId, this.dataService.currentDataPackage, fileUid),
    );

    this.newFileSelected.next({
      name: this.currentFileMeta.name,
      url: this.currentFileMeta.url,
      mimeType: this.currentFileMeta.fileType,
    });
    this.update();

    this.fileName = this.currentFileMeta.name;

    if (!this.data) throw new Error('Data not found');
    this.data.value = fileUid;

    this.fileSelectModalRef?.close(this.currentFileMeta.uid);
    this.clearPopovers();
  }

  /**
   * Removes the file from the data instance, but doesn't delete the file
   */
  async clearFile() {
    if (this.currentFileMeta) {
      try {
        this.currentFileMeta = undefined;
        if (this.fileType == 'audio') {
          this.newFileSelected.next({ name: '', url: '', mimeType: '' });
        } else if (this.fileType == 'image') {
          this.newFileSelected.next({ name: '', url: 'assets/images/select_image.png', mimeType: 'image/png' });
        } else if (this.fileType == 'video') {
          this.newFileSelected.next({ name: '', url: '', mimeType: 'video/mp4' });
        }
        this.alertService.showAlert('Cleared ' + this.data?.name, BootstrapClass.INFO);
        this.update();
      } catch (error: unknown) {
        window.alert('An error occurred: ' + (error as Error).message);
      }
    }
  }

  async deleteFile(fileUid: string, fileName: string) {
    if (confirm('Are you sure you want to delete ' + fileName + '?')) {
      try {
        await this.dataService.deleteFile(fileUid);
        this.loadedFiles = await this.dataService.getFilesMeta(this.dataService.currentDataPackage);
        if (this.currentFileMeta?.uid === fileUid) {
          this.currentFileMeta = undefined;
          this.currentURL = '';
          if (this.fileType == 'image') {
            this.newFileSelected.next({ name: '', url: 'assets/images/select_image.png', mimeType: 'image/png' });
          } else if (this.fileType == 'audio') {
            this.newFileSelected.next({ name: '', url: '', mimeType: '' });
          } else if (this.fileType == 'video') {
            this.newFileSelected.next({ name: '', url: '', mimeType: 'video/mp4' });
          }
          this.update();
        }
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          window.alert('This file is being used somewhere and cannot be deleted.');
        } else {
          // Handle other errors
          window.alert('An error occurred: ' + (error as Error).message);
        }
      }
    }
  }

  clearPopovers() {
    setTimeout(() => {
      document.querySelectorAll('ngb-popover-window').forEach((popover) => {
        // Hack for now to delete the popover elements manually...
        popover.remove();
      });
    }, 800);
  }

  openReplaceModal(uploadModal: TemplateRef<unknown>) {
    this.prepareReplace().then(() => {
      this.openModal(uploadModal);
      const fileUpload = document.getElementById('fileUpload');
      if (fileUpload) fileUpload.click();
    });
  }
}
