import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { DataService } from './data-management/data.service';
import { HTTPRequestService } from './data-management/HTTP-request.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private dataService: DataService,
    private requestService: HTTPRequestService,
    private router: Router,
  ) {}

  /**
   * A method to find the url of a data instance, if it exists. Also handles special cases like MissionInfo and Modules.
   * @param uid
   */
  public async findDataInstanceUrl(uid: string): Promise<[string[], NavigationExtras?]> {
    const [dataInstance, { parent, traversedChildren }] = await Promise.all([
      this.dataService.getDataInstance(uid, true),
      lastValueFrom(this.requestService.getDataInstanceParent(this.dataService.currentGameId, this.dataService.currentDataPackage, uid)),
    ]);

    switch (parent.dataType) {
      case 'MissionInfo': {
        const activityEnum = this.dataService.getEnumType('Activity');
        const activity = activityEnum.options.includes(dataInstance.dataType)
          ? dataInstance.uid
          : traversedChildren.find((child) => activityEnum.options.includes(child.dataType))?.uid;

        return [
          [`/home/mission/${parent.uid}`],
          {
            queryParams: {
              activity: activity,
            },
          },
        ];
      }

      case 'Module': {
        return [
          [`/home/module/${parent.uid}`],
          {
            queryParams: {
              kennisNode: dataInstance.uid,
            },
          },
        ];
      }

      default: {
        return [[`/home/${parent.dataType}/${parent.uid}`]];
      }
    }
  }

  public async navigateToDataInstance(uid: string) {
    const [url, extras] = await this.findDataInstanceUrl(uid);
    await this.router.navigate(url, extras);
  }
}
