import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../../_services/data-management/data.service';
import { DataInstance } from '../../../models/data/DataInstance';
import { ContextMenuOptions, ShapeType, TargetType, VisualTarget } from './types';
import { Vector2 } from '../../../models/types/Vector2';
import { FieldData as _FieldData, FieldData } from '../../../models/data/FieldData';
import { FieldValue } from '../../../models/data/FieldValue';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Preset, Presets } from '../visual-editor/presets';

@Component({
  selector: 'app-canvas-editor',
  templateUrl: './canvas-editor.component.html',
  styleUrls: ['./canvas-editor.component.scss'],
})
export class CanvasEditorComponent implements OnInit, OnDestroy {
  // Just so that the field Directive will not throw errors of not having a data input
  @Input() data: FieldData<string> | undefined;

  dataInstance: DataInstance | undefined;

  draggableInstances: DataInstance[] = [];
  targetInstances: DataInstance[] = [];
  visualElementInstances: DataInstance[] = [];
  mapPinInstances: DataInstance[] = [];

  visualTargets: Record<string, VisualTarget> = {};
  visualTargetNames: Record<string, string> = {};

  background?: _FieldData<string>;
  backgroundInstance?: DataInstance;
  canvasRatio: Vector2 = { x: 1.0, y: 1.0 };
  selected = '';

  protected contextMenu: ContextMenuOptions = {};
  protected readonly Object = Object;
  private routeQueryParamSub?: Subscription;
  private routeParamSub?: Subscription;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.routeQueryParamSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      const activityInstanceUid = params['activity'];
      if (!activityInstanceUid) {
        console.warn('No activity instance found, maybe it is a map?');
        return;
      }
      await this.loadEditor(activityInstanceUid);
    });

    this.routeParamSub = this.activatedRoute.params.subscribe(async (params) => {
      const mapInstanceUid = params['mapUid'];
      if (!mapInstanceUid) {
        console.warn('No map instance found, maybe it is an activity?');
        return;
      }
      await this.loadEditor(mapInstanceUid);
    });
  }

  ngOnDestroy() {
    this.routeQueryParamSub?.unsubscribe();
    this.routeParamSub?.unsubscribe();
  }

  async addVisualElement(
    shapeType: 'Circle' | 'Rectangle',
    position = {
      x: 0.5,
      y: 0.5,
    },
    size = {
      x: 0.04,
      y: 0.06,
    },
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    const shapeInstance = await this.dataService.initStruct(shapeType);
    if (shapeType === 'Circle') {
      await this.dataService.updateFieldValue(shapeInstance.uid, 'radius', radius);
    } else {
      await this.dataService.updateFieldValue(shapeInstance.uid, 'size', size);
    }

    const visualElementInstance = await this.dataService.initStruct('VisualElement');
    await this.dataService.updateFieldValue(visualElementInstance.uid, 'position', position);
    for (const fieldValue of visualElementInstance.fieldValues) {
      if (fieldValue.field === 'shape') {
        fieldValue.value = shapeInstance.uid;
        await this.dataService.updateFieldValue(visualElementInstance.uid, 'shape', fieldValue.value);
      }
    }

    for (const fieldValue of this.dataInstance.fieldValues) {
      if (fieldValue.field === 'visualElements') {
        if (!Array.isArray(fieldValue.value)) {
          fieldValue.value = [];
        }
        (fieldValue.value as string[]).push(visualElementInstance.uid);
        await this.dataService.updateFieldValue(this.dataInstance.uid, 'visualElements', fieldValue.value);
      }
    }

    this.visualElementInstances.push(visualElementInstance);
    this.visualTargetNames[visualElementInstance.uid] = visualElementInstance.uid as string;
    await this.setVisualTargets();
  }

  async addMapPinLocation(position = { x: 0.5, y: 0.5 }, panelPosition = { x: 0.5, y: 0.5 }) {
    if (!this.dataInstance) return;

    const instance = await this.dataService.initStruct('MapPinLocation');
    await this.dataService.updateFieldValue(instance.uid, 'position', position);
    await this.dataService.updateFieldValue(instance.uid, 'panelPosition', panelPosition);

    // Since mapLocations is not required, some maps won't have the field which means we need to explicitly save it in that case
    let hasMapLocations = false;
    for (const fieldValue of this.dataInstance.fieldValues) {
      if (fieldValue.field === 'mapLocations') {
        hasMapLocations = true;
        if (!Array.isArray(fieldValue.value)) {
          fieldValue.value = [];
        }
        (fieldValue.value as string[]).push(instance.uid);
        await this.dataService.updateFieldValue(this.dataInstance.uid, 'mapLocations', fieldValue.value);
      }
    }
    if (!hasMapLocations) {
      await this.dataService.updateFieldValue(this.dataInstance.uid, 'mapLocations', [instance.uid]);
    }

    this.mapPinInstances.push(instance);
    this.visualTargetNames[instance.uid] = instance.uid as string;
    await this.setVisualTargets();
  }

  async addInstance(
    structType: 'Draggable' | 'DropPoint' | 'DropArea' | 'ClickTarget',
    shapeType: 'Rectangle' | 'Circle',
    position = {
      x: 0.5,
      y: 0.5,
    },
    size = {
      x: 0.04,
      y: 0.06,
    },
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    const instanceStruct = await this.dataService.initStruct(structType);

    const visualElementInstanceUid = instanceStruct.fieldValues.find((fieldValue) => fieldValue.field === 'visualElement');
    if (!visualElementInstanceUid) {
      console.warn('No visual element found for instance ' + instanceStruct.uid);
      return;
    }
    const visualElementInstance = await this.dataService.getDataInstance(visualElementInstanceUid.value as string);
    await this.dataService.updateFieldValue(visualElementInstance.uid, 'position', position);

    const shapeInstance = await this.dataService.initStruct(shapeType);
    if (shapeType === 'Circle') {
      await this.dataService.updateFieldValue(shapeInstance.uid, 'radius', radius);
    } else {
      await this.dataService.updateFieldValue(shapeInstance.uid, 'size', size);
    }
    await this.dataService.updateFieldValue(visualElementInstance.uid, 'position', position);
    for (const fieldValue of visualElementInstance.fieldValues) {
      if (fieldValue.field === 'shape') {
        fieldValue.value = shapeInstance.uid;
        await this.dataService.updateFieldValue(visualElementInstance.uid, 'shape', fieldValue.value);
      }
    }

    let fieldValue = undefined;
    switch (structType) {
      case 'Draggable':
        fieldValue = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'draggables') as FieldValue<string[]>;
        if (!fieldValue) throw new Error('Field draggables not found in activity' + this.dataInstance.uid);
        fieldValue.value.push(instanceStruct.uid);
        await this.dataService.updateFieldValue(this.dataInstance.uid, 'draggables', fieldValue.value);
        this.draggableInstances.push(instanceStruct);
        break;
      case 'DropPoint':
      case 'DropArea':
      case 'ClickTarget':
        fieldValue = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'targets') as FieldValue<string[]>;
        if (!fieldValue) throw new Error('Field targets not found in activity' + this.dataInstance.uid);
        fieldValue.value.push(instanceStruct.uid);
        await this.dataService.updateFieldValue(this.dataInstance.uid, 'targets', fieldValue.value);
        this.targetInstances.push(instanceStruct);
        break;
      default:
        return;
    }

    this.visualTargetNames[instanceStruct.uid] = instanceStruct.uid as string;
    await this.setVisualTargets();
  }

  async addPreset(preset: Preset) {
    if (!this.dataInstance || !preset) return;
    for (const shape of preset.shapes) {
      if (shape.isVisualElement) {
        if (shape.type === 'Circle') {
          await this.addVisualElement(shape.type, { x: shape.x, y: shape.y }, undefined, shape.radius);
        } else {
          await this.addVisualElement(
            shape.type,
            { x: shape.x, y: shape.y },
            {
              x: shape.size?.width ?? 0.04,
              y: shape.size?.height ?? 0.06,
            },
          );
        }
      } else {
        if (shape.type === 'Circle') {
          await this.addInstance('ClickTarget', shape.type, { x: shape.x, y: shape.y }, undefined, shape.radius);
        } else {
          await this.addInstance(
            'ClickTarget',
            shape.type,
            { x: shape.x, y: shape.y },
            {
              x: shape.size?.width ?? 0.04,
              y: shape.size?.height ?? 0.06,
            },
          );
        }
      }
    }
  }

  async getInstances(field: 'draggables' | 'targets' | 'visualElements' | 'mapLocations') {
    if (!this.dataInstance) return;

    const fieldValue = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === field) as FieldValue<string[]>;
    const uids = fieldValue?.value ?? [];
    const instances = (await Promise.all(
      uids.map(async (uid) => {
        const instance = await this.dataService.getDataInstance(uid);
        const nameField = this.dataService.getStructType(instance.dataType).fields.find((f) => f.fieldId === 'name');
        if (nameField) {
          let fieldValue = instance.fieldValues.find((fieldValue) => fieldValue.field === 'name')?.value;
          if (!fieldValue) fieldValue = instance.uid;
          this.visualTargetNames[instance.uid] = fieldValue as string;
        } else {
          this.visualTargetNames[instance.uid] = instance.uid as string;
        }
        return instance;
      }),
    )) as DataInstance[];

    switch (field) {
      case 'draggables':
        this.draggableInstances = instances;
        break;
      case 'targets':
        this.targetInstances = instances;
        break;
      case 'visualElements':
        this.visualElementInstances = instances;
        break;
      case 'mapLocations':
        this.mapPinInstances = instances;
        break;
    }

    await this.setVisualTargets();
  }

  async setVisualTargets() {
    const vTargets = { ...this.visualTargets };

    const currentInstanceUids = [
      ...this.draggableInstances,
      ...this.targetInstances,
      ...this.visualElementInstances,
      ...this.mapPinInstances,
    ].map((instance) => instance.uid);
    for (const uid in this.visualTargets) {
      if (!currentInstanceUids.includes(uid)) {
        delete vTargets[uid];
      }
    }

    for (const instance of [...this.draggableInstances, ...this.targetInstances, ...this.visualElementInstances, ...this.mapPinInstances]) {
      // Add the visual targets that are new
      if (!Object.prototype.hasOwnProperty.call(vTargets, instance.uid)) {
        let visualElementInstance = undefined;
        let targetType = TargetType.DROP_TARGET;
        let hideBorder = false;
        let isCorrect = false;

        switch (instance.dataType) {
          case 'VisualElement':
            visualElementInstance = instance;
            targetType = TargetType.VISUAL_ELEMENT;
            break;
          case 'ClickTarget':
            targetType = TargetType.CLICK_TARGET;
            break;
          case 'MapPinLocation':
            targetType = TargetType.MAP_PIN;
            break;
          case 'Draggable':
            targetType = TargetType.DRAGGABLE;
            break;
        }

        if (instance.dataType !== 'VisualElement' && instance.dataType !== 'MapPinLocation') {
          const visualElementField = instance.fieldValues.find((fieldValue) => fieldValue.field === 'visualElement');
          if (!visualElementField) {
            console.warn('No visual element found for instance ' + instance.uid);
            continue;
          }
          visualElementInstance = await this.dataService.getDataInstance(visualElementField.value as string);
          if (!visualElementInstance) {
            console.warn('Visual element not found for instance ' + instance.uid);
            continue;
          }

          const isCorrectField = instance.fieldValues.find((fieldValue) => fieldValue.field === 'isCorrect');
          if (isCorrectField) {
            isCorrect = isCorrectField.value as boolean;
          }
          const hideBorderField = instance.fieldValues.find((fieldValue) => fieldValue.field === 'hideInteractableIndicator');
          if (hideBorderField) {
            hideBorder = hideBorderField.value as boolean;
          }
        }

        if (instance.dataType !== 'MapPinLocation') {
          const shapeInstanceField = visualElementInstance!.fieldValues.find((fieldValue) => fieldValue.field === 'shape');
          if (!shapeInstanceField) {
            console.warn('VisualElement instance does not have a shape');
            return;
          }
          const shapeInstance = await this.dataService.getDataInstance(shapeInstanceField.value as string);
          const shapeType = shapeInstance.dataType as 'Circle' | 'Rectangle';

          const position = (visualElementInstance!.fieldValues.find((fieldValue) => fieldValue.field === 'position')?.value as Vector2) ?? {
            x: 0.5,
            y: 0.5,
          };
          const size = (shapeInstance.fieldValues.find((fieldValue) => fieldValue.field === 'size')?.value as Vector2) ?? {
            width: 0.04,
            height: 0.06,
          };
          const radius = (shapeInstance.fieldValues.find((fieldValue) => fieldValue.field === 'radius')?.value as number) ?? 0.04;
          let media = (visualElementInstance!.fieldValues.find((fieldValue) => fieldValue.field === 'media')?.value as string) ?? '';
          let placeableMedia = undefined;
          if (media) {
            placeableMedia = await this.dataService.getDataInstance(media);
            if (!placeableMedia) {
              console.warn('Placeable media not found for instance ' + media);
              continue;
            }
            const imageField = placeableMedia.fieldValues.find((fieldValue) => fieldValue.field === 'image');
            if (imageField) {
              media = imageField.value as string;
            } else {
              media = (placeableMedia.fieldValues.find((fieldValue) => fieldValue.field === 'image')?.value as string) ?? '';
            }
          }

          vTargets[instance.uid] = {
            type: shapeType,
            position: { dataInstanceUid: visualElementInstance!.uid, fieldValue: { field: 'position', value: position } },
            size: { dataInstanceUid: shapeInstance.uid, fieldValue: { field: 'size', value: size } },
            radius: { dataInstanceUid: shapeInstance.uid, fieldValue: { field: 'radius', value: radius } },
            media: { dataInstanceUid: placeableMedia?.uid, fieldValue: { field: 'media', value: media } },
            targetType: targetType,
            isCorrect: isCorrect,
            hide: hideBorder,
            uid: instance.uid,
            name: this.visualTargetNames[instance.uid],
          } as VisualTarget; // todo: fix this type
        } else {
          const position = (instance.fieldValues.find((fieldValue) => fieldValue.field === 'position')?.value as Vector2) ?? {
            x: 0.5,
            y: 0.5,
          };
          const panelPosition = (instance.fieldValues.find((fieldValue) => fieldValue.field === 'panelPosition')?.value as Vector2) ?? {
            x: 0.5,
            y: 0.5,
          };
          vTargets[instance.uid] = {
            type: ShapeType.PIN,
            position: { dataInstanceUid: instance.uid, fieldValue: { field: 'position', value: position } },
            panelPosition: { dataInstanceUid: instance.uid, fieldValue: { field: 'panelPosition', value: panelPosition } },
            targetType: targetType,
            uid: instance.uid,
            name: this.visualTargetNames[instance.uid],
          } as VisualTarget; // todo: fix this type
        }
      }
    }

    this.visualTargets = vTargets;
  }

  onUpdateName(update: { uid: string; name: string }) {
    this.visualTargetNames[update.uid] = update.name;
  }

  async deleteInstance(instance: DataInstance, field: 'draggables' | 'targets' | 'visualElements' | 'mapLocations') {
    if (!this.dataInstance) return;
    const fieldValue = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === field) as FieldValue<string[]>;
    if (!fieldValue) {
      console.warn('Field not found');
      return;
    }

    fieldValue.value = fieldValue.value.filter((uid) => uid !== instance.uid);
    await this.dataService.updateFieldValue(this.dataInstance.uid, field, fieldValue.value);
    await this.dataService.deleteDataInstance(instance);

    await this.getInstances(field);
  }

  private async loadEditor(activityInstanceUid: string) {
    this.dataInstance = await this.dataService.getDataInstance(activityInstanceUid);
    if (!this.dataInstance) {
      console.warn('Activity instance not found');
      return;
    }

    const backgroundField = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'background');
    if (!backgroundField) {
      console.warn('No background found');
      return;
    }

    const placeableMedia = await this.dataService.getDataInstance(backgroundField.value as string);
    this.backgroundInstance = placeableMedia;
    const backgroundFieldValue = placeableMedia.fieldValues.find((fieldValue) => {
      return fieldValue.field === 'image' || fieldValue.field === 'video' || fieldValue.field === 'color';
    }) as FieldValue<string> | undefined;
    if (!backgroundFieldValue) {
      console.warn('No background found');
      return;
    }
    switch (backgroundFieldValue.field) {
      case 'color':
        this.background = {
          fieldId: 'color',
          fieldType: placeableMedia.dataType,
          value: backgroundFieldValue.value,
          name: 'Color',
          dataInstanceUid: backgroundField.value as string,
        } as _FieldData<string>;
        break;
      case 'video':
        this.background = {
          fieldId: 'video',
          fieldType: placeableMedia.dataType,
          value: backgroundFieldValue.value,
          name: 'Video',
          dataInstanceUid: backgroundField.value as string,
        } as _FieldData<string>;
        break;
      case 'image':
        this.background = {
          fieldId: 'image',
          fieldType: placeableMedia.dataType,
          value: backgroundFieldValue.value,
          name: 'Image',
          dataInstanceUid: backgroundField.value as string,
        } as _FieldData<string>;
        break;
      default:
        console.warn('No background found');
    }

    const canvasRatio = this.dataInstance.fieldValues.find((fieldValue) => fieldValue.field === 'canvasRatio') as
      | FieldValue<Vector2>
      | undefined;

    if (canvasRatio) {
      this.canvasRatio = canvasRatio.value;
    }

    switch (this.dataInstance.dataType) {
      case 'DragAndDropActivity': {
        this.contextMenu = {
          'Draggable objects': [
            {
              label: 'Rectangle draggable',
              action: (x: number, y: number) => this.addInstance('Draggable', 'Rectangle', { x, y }),
            },
          ],
          'Drop targets': [
            {
              label: 'Rectangle drop point',
              action: (x: number, y: number) => this.addInstance('DropPoint', 'Rectangle', { x, y }),
            },
            {
              label: 'Rectangle drop area',
              action: (x: number, y: number) => this.addInstance('DropArea', 'Rectangle', { x, y }),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', { x, y }),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', { x, y }),
            },
          ],
        };
        await this.getInstances('draggables');
        await this.getInstances('targets');
        await this.getInstances('visualElements');
        break;
      }
      case 'ClickActivity': {
        this.contextMenu = {
          Targets: [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Rectangle', { x, y }),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Circle', { x, y }),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', { x, y }),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', { x, y }),
            },
          ],
          Presets: Presets.map((preset) => ({
            label: preset.name,
            action: () => this.addPreset(preset),
          })),
        };
        await this.getInstances('targets');
        await this.getInstances('visualElements');
        break;
      }
      case 'Map': {
        this.contextMenu = {
          Pins: [
            {
              label: 'Add pin',
              action: (x: number, y: number) => this.addMapPinLocation({ x, y }, { x, y }),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', { x, y }),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', { x, y }),
            },
          ],
        };
        await this.getInstances('mapLocations');
        await this.getInstances('visualElements');
        break;
      }
    }
  }
}
