<div class="container d-flex flex-column gap-2 py-4">
  <div class="resource-title">
    <h1 [ngbPopover]="structTypeDescription" class="text-center text-white pb-2 drop-shadow"
        triggers="hover">{{ structType }}</h1>
    <button (click)="createResource()" class="btn btn-primary"><i class="bi bi-plus-circle"></i></button>
  </div>
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th *ngFor="let field of fieldsToDisplay" scope="col">{{ field.name }}</th>
      <th scope="col">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let resource of resources">
      <td (click)="openResource(resource.uid)" *ngFor="let field of fieldsToDisplay" class="cursor-pointer">
        {{ getFieldDisplayValue(resource, field) }}
      </td>
      <td>
        <div class="d-flex gap-1 justify-content-end">
          <button (click)="openResource(resource.uid)" class="btn btn-no-space btn-outline-dark align-right"
                  ngbPopover="Edit {{ this.resourceName(resource.uid) }}" triggers="hover">
            <span class="bi bi-pencil-square"></span>
          </button>

          <button (click)="copyResourceIdToClipboard(resource.uid)"
                  class="btn btn-no-space btn-outline-dark align-right"
                  ngbPopover="Copy ID" triggers="hover"
          >
            <span class="bi bi-code"></span>
          </button>

          <button
            (click)="duplicateResource(resource.uid)" class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Duplicate" triggers="hover"
          >
            <i class="bi bi-copy"></i>
          </button>

          <button (click)="deleteResource(resource.uid)"
                  class="btn btn-no-space btn-outline-dark align-right"
                  ngbPopover="Delete {{ this.resourceName(resource.uid) }}" triggers="hover"
          >
            <span class="bi bi-trash"></span>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="text-center">
    <button (click)="createResource()" class="btn btn-primary">
      <i class="bi bi-plus-circle"></i>
    </button>
  </div>
</div>

<ng-template #duplicationCompleteModal let-modal>
  <app-duplication-complete-modal
    (openInstance)="openResource(this.newResource!!.uid)"
    [info]="{ name: this.resourceName(this.newResource!!.uid) ?? this.newResource!!.uid }"
    [modal]="modal"
  />
</ng-template>
