import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FieldData } from '../../../../models/data/FieldData';
import { Field } from '../../../../models/schema/Field';
import { DataService } from '../../../../_services/data-management/data.service';
import { Resource } from '../../../../models/data/Resource';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { DynamicFieldComponent } from '../../dynamic-field.component';
import { instant } from '../../../../_services/utils';
import { Router } from '@angular/router';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';
import { NavigationService } from '../../../../_services/navigation.service';

@Component({
  selector: 'app-selector-field',
  templateUrl: './selector-field.component.html',
  styleUrls: ['./selector-field.component.scss'],
})
export class SelectorFieldComponent implements OnInit, OnChanges, DynamicFieldComponent<FieldData<string | string[]> | undefined> {
  @Input({
    transform: (value: FieldData<string | string[] | unknown> | undefined) =>
      !value ? undefined : (value as FieldData<string | string[]>),
  })
  data: FieldData<string | string[]> | undefined;

  @Input() choices: Resource[] = [];
  @Input() loading = false;
  @Output() reload = new EventEmitter<void>();

  /**
   * If the field is not a list, the fieldData will contain an array with just one item
   */
  selectedFieldData: FieldData<string[]> | undefined;
  field?: Field;
  isList = false;
  structType? = '';

  constructor(
    protected dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private navigationService: NavigationService,
    protected loadingScreenService: LoadingScreenService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes && !changes['data'].isFirstChange()) {
      this.initData(changes['data'].currentValue);
    }

    if ('choices' in changes) {
      this.updateChoices();
    }
  }

  async ngOnInit() {
    if (!this.data) return;

    // If the field is a list, set the isList flag to true
    const dataInstance = await this.dataService.getDataInstance(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    this.field = this.dataService.getField(this.data.fieldId, dataInstance.dataType);
    this.isList = this.field.type.startsWith('List<');

    // TODO: extend to also support enum, which requires a drowdown to pick the enum option to create
    if (this.data.fieldType.startsWith('StructRef<') || this.data.fieldType.startsWith('List<StructRef<')) {
      const dataType = this.dataService.getTypeIdFromRefType(this.field.type);
      if (this.dataService.getResourceStructs().includes(dataType)) {
        this.structType = dataType;
      }
    }

    this.initData(this.data);

    // Add an empty choice to the front of the list, if it is not a list
    this.updateChoices();
  }

  // Add and remove are only used for lists
  addResourceSelector() {
    if (!this.selectedFieldData || !this.data) throw new Error('Data not found');

    // If there are no choices, show warning alert
    if (this.choices.length < 1) {
      this.alertService.showAlert('No ' + this.data.name + ' found', BootstrapClass.WARNING);
      return;
    }

    // Add the value to the array, which also initializes a new dropdown
    if (this.isList) this.selectedFieldData.value.push(this.choices[0].value);
    else this.selectedFieldData.value = [this.choices[0].value];

    this.saveUpdates().then();
  }

  removeResourceSelector(indexToRemove: number) {
    if (!this.data || !this.selectedFieldData) throw new Error('Data not found');

    // Remove the value from the array
    if (this.isList) this.selectedFieldData.value.splice(indexToRemove, 1);
    else this.selectedFieldData.value = [];

    this.saveUpdates().then();
  }

  // Update the data instance when the dropdown is changed
  saveUpdates() {
    return instant(() => {
      if (!this.selectedFieldData) throw new Error('Data not found');
      try {
        this.dataService
          .updateFieldValue(
            this.selectedFieldData.dataInstanceUid,
            this.selectedFieldData.fieldId,
            this.isList ? this.selectedFieldData.value : this.selectedFieldData.value[0],
          )
          .then();
        this.alertService.showAlert('Updated ' + this.selectedFieldData.name + '...', BootstrapClass.INFO);
        return;
      } catch (e) {
        console.error(e);
      }
      return;
    });
  }

  async onViewStruct(index: number) {
    if (!this.selectedFieldData) return;

    console.log(this.selectedFieldData.value);
    const instanceUid = this.selectedFieldData.value[index];
    const url = await this.navigationService.findDataInstanceUrl(instanceUid);
    await this.router.navigate(url[0], url[1]);
  }

  async onAddInstanceOfStruct(index: number) {
    const data = this.data;
    const structType = this.structType;
    if (!data || !structType) return;

    await this.loadingScreenService.show(async () => {
      try {
        const newStructInstance = await this.dataService.initStruct(structType);
        if (this.isList) {
          const currentFieldValue = data.value as string[];
          currentFieldValue.splice(index, 1, newStructInstance.uid);
          await this.dataService.updateFieldValue(data.dataInstanceUid, data.fieldId, currentFieldValue);
          await this.onViewStruct(index);
        } else {
          await this.dataService.updateFieldValue(data.dataInstanceUid, data.fieldId, newStructInstance.uid);
          this.data!.value = [newStructInstance.uid];
          await this.onViewStruct(0);
        }
        this.alertService.showAlert('Created new ' + structType + '...', BootstrapClass.SUCCESS);
      } catch (e) {
        this.alertService.showAlert('Failed to create new ' + structType + '...', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  reloadChoices() {
    this.reload.emit();
  }

  private updateChoices() {
    if (!this.isList && this.field && !this.choices.find((s) => s.value === '')) {
      const typeId = this.dataService.getTypeIdFromRefType(this.field.type);
      this.choices = [{ name: 'Select ' + typeId, value: '' }, ...this.choices];
    }
  }

  private initData(data: FieldData<string | string[]>) {
    this.selectedFieldData = this.convertData(data);

    if (!this.selectedFieldData.value) {
      this.selectedFieldData.value = [];
    }
  }

  private convertData(data: FieldData<string | string[]>) {
    if (!Array.isArray(data.value)) data.value = this.isList ? [] : [data.value];
    return data as FieldData<string[]>;
  }
}
