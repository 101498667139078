<div class="container-fluid p-4">
  <div class="card shadow-sm">
    <div class="card-header">
      <h2>CAS Maintenance</h2>
    </div>

    <div class="card-body">
      <p>
        Data cleanup searches for unused and broken data, and tries to fix it. Usually by removing the broken data, or
        clearing references.
      </p>

      <div class="d-flex flex-row gap-1">
        <button
          (click)="runAnalyzeData()"
          [disabled]="$analyzeRequest !== undefined"
          class="btn btn-primary"
        >
          Analyze Data
        </button>

        <button
          (click)="runSelectedActions()"
          *ngIf="analyzedData !== undefined"
          [disabled]="!selectedCleanupActionsCount"
          class="btn btn-danger"
        >
          Run Selected Actions
        </button>
      </div>

      <div *ngIf="$cleanupRequest !== undefined && cleanupResponse === undefined">
        <ng-container
          *ngTemplateOutlet="loading; context: { text: 'Performing cleanup actions..' }"
        />
      </div>

      <div *ngIf="$analyzeRequest !== undefined && analyzedData === undefined">
        <ng-container
          *ngTemplateOutlet="loading; context: { text: 'Analyzing data..' }"
        />
      </div>

      <ng-template #loading let-text="text">
        <app-spinner [text]="text" />
      </ng-template>

      <div *ngIf="cleanupResponse !== undefined" class="mt-4">
        <h5>Cleanup Response</h5>
        <p>Successfully ran <span class="fw-bold">{{ cleanupResponse.actions }}</span> actions</p>
      </div>

      <div *ngIf="analyzedData !== undefined" class="mt-4">
        <p class="text-muted small">
          Scanned {{ analyzedData.totalDataInstances }} data instances
          over {{ analyzedData.iterations }} iteration(s) in {{ analyzedData.duration }}ms
        </p>

        <h5>Suggested actions ({{ analyzedData.total }})</h5>

        <table *ngIf="analyzedData.total > 0" class="table border table-hover">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">UID</th>
            <th scope="col">Data Type</th>
            <th scope="col">Field Value</th>
            <th scope="col">Problem</th>
            <th scope="col">Suggested Action</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let resultItem of analyzedData.items" class="cursor-pointer">
            <td>
              <input
                (ngModelChange)="updateSelectionCount()"
                *ngIf="resultItem.action !== DataCleanupAction.MANUAL_FIX"
                [(ngModel)]="selectedCleanupActions[resultItem.dataInstanceUid + ':' +  resultItem.fieldValue]"
                class="form-check-input"
                type="checkbox"
              />
            </td>
            <td (click)="clickItem(resultItem)">
              {{ resultItem.dataInstanceUid }}
            </td>
            <td (click)="clickItem(resultItem)">
              {{ resultItem.dataType }}
            </td>
            <td (click)="clickItem(resultItem)">
              <p *ngIf="resultItem.fieldValue || resultItem.fieldId; else noValue">
                '{{ resultItem.fieldValue }}' ({{ resultItem.fieldId }})
              </p>
              <ng-template #noValue>-</ng-template>
            </td>
            <td (click)="clickItem(resultItem)">
              <span [innerHTML]="resultItem.description" class="description"></span>&nbsp;<span
              class="small text-muted">({{ resultItem.problem }})</span>
            </td>
            <td
              (click)="clickItem(resultItem)"
              [ngClass]="{'text-bg-danger': resultItem.action === DataCleanupAction.DELETE_INSTANCE, 'text-bg-warning': resultItem.action === DataCleanupAction.MANUAL_FIX}"
            >
              {{ resultItem.action }}
            </td>
          </tr>
          </tbody>
        </table>

        <p *ngIf="analyzedData.total === 0">
          No suggestions
        </p>

        <hr class="my-4">

        <div class="d-flex flex-row gap-3">
          <div class="w-100">
            <h5>Suggestions per data type</h5>

            <ul
              *ngIf="dataTypes && Object.entries(dataTypes).length; else noDataTypes"
              class="list-group"
            >
              <li *ngFor="let count of dataTypes | keyvalue" class="list-group-item">
                {{ count.key }}: <span class="fw-bold">{{ count.value }}</span>
              </li>
            </ul>

            <ng-template #noDataTypes>
              No data types
            </ng-template>
          </div>

          <div class="w-100">
            <h5>Suggested Actions</h5>

            <ul
              *ngIf="suggestedActions && Object.entries(suggestedActions).length; else noSuggestions"
              class="list-group"
            >
              <li *ngFor="let count of suggestedActions | keyvalue" class="list-group-item">
                {{ count.key }}: <span class="fw-bold">{{ count.value }}</span>
              </li>
            </ul>

            <ng-template #noSuggestions>
              No suggestions
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow-sm mt-2">
    <div class="card-header">
      <h3>Cache</h3>
    </div>
    <div class="card-body">
      <p>
        Tells the backend to clear it's cache. Useful when you suspect the backend is caching data that is no longer
        valid, for example, when you modified the database directly.
      </p>

      <button
        (click)="clearCache()"
        [disabled]="$clearCacheRequest !== undefined"
        class="btn btn-danger"
      >
        Clear Cache
      </button>

      <div *ngIf="$clearCacheRequest !== undefined">
        <ng-container
          *ngTemplateOutlet="loading; context: { text: 'Clearing cache..' }"
        />
      </div>
    </div>
  </div>
</div>
