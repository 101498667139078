import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Konva from 'konva';
import { Vector2 } from '../../../models/types/Vector2';
import { FieldData as _FieldData } from '../../../models/data/FieldData';
import { DataService } from '../../../_services/data-management/data.service';
import { distinctUntilChanged, filter, map, startWith, Subscription, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Resource } from '../../../models/data/Resource';
import { DataInstance } from '../../../models/data/DataInstance';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { clamp, debounce } from '../../../_services/utils';
import { ContextMenuOptions, DataInstanceFieldData, Orientation, ShapeType, TargetType, VisualTarget } from '../canvas-editor/types';
import { Preset, Presets } from './presets';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-visual-editor',
  templateUrl: './visual-editor.component.html',
  styleUrls: ['./visual-editor.component.scss'],
})
export class VisualEditorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('stageContainer') stageContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('stageParent') stageParent!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('backgroundVideo') backgroundVideo?: ElementRef<HTMLVideoElement>;

  @Input() targets: Record<string, VisualTarget> = {};
  @Input() visualElementsData: Record<string, DataInstance> = {};
  @Input() background!: _FieldData<string>;
  @Input() backgroundInstance!: DataInstance;
  @Input() canvasRatio!: Vector2;
  @Input() contextMenuOptions?: ContextMenuOptions;

  @Output() selected = new EventEmitter<string>();
  @Output() shapeCreated = new EventEmitter<Konva.Shape[]>();
  @Output() addPreset = new EventEmitter<Preset>();
  @Output() nameChanged = new EventEmitter<{ uid: string; name: string }>();

  canvasOrientation: Orientation =
    environment.aspectRatio.width > environment.aspectRatio.height ? Orientation.Horizontal : Orientation.Vertical;

  activityInstance: DataInstance | undefined;
  newCanvasRatioWidth = '1';
  newCanvasRatioHeight = '1';
  choices: Resource[] = [];
  currentChoice: string | undefined;
  placeableMediaFieldData: _FieldData<string> | undefined;
  backgroundColor?: string;
  selectedPreset?: Preset;
  loading = false;

  currentBackgroundVideoUrl?: string;
  currentBackgroundName = '';
  backgroundImageSize: Vector2 | undefined;
  protected editMenuSubject?: Konva.Shape;
  protected showContextMenu?: { x: number; y: number };
  protected readonly Orientation = Orientation;
  protected readonly presets = Presets;
  private stage?: Konva.Stage;
  private stageSize: Vector2 = { x: 0, y: 0 };
  private stagePosition: Vector2 = { x: 0, y: 0 };
  private canvasSize: Vector2 = { x: 0, y: 0 };
  private shapeLayer = new Konva.Layer();
  private backgroundLayer = new Konva.Layer();
  private transformer = new Konva.Transformer({
    rotateEnabled: false,
    ignoreStroke: true,
  });
  private tooltipText = new Konva.Text({
    text: '',
    fontFamily: 'Calibri',
    fontSize: 24,
    padding: 5,
    textFill: 'white',
    fill: 'black',
    alpha: 0.75,
    visible: false,
    listening: false,
  });
  private currentBackgroundUrl?: string;
  private currentBackgroundMimeType?: string;
  private subscriptions: Subscription[] = [];
  private mousePosition = { x: 0, y: 0 };
  private routeSub?: Subscription;
  private instanceUpdatedSub?: Subscription;

  constructor(
    private readonly dataService: DataService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      const activityInstanceUid = params['activity'];
      if (!activityInstanceUid) {
        return;
      }
      this.activityInstance = await this.dataService.getDataInstance(activityInstanceUid);
    });

    this.instanceUpdatedSub = this.dataService.instanceUpdated$.subscribe(async (instance) => {
      if (
        !instance ||
        (instance.dataType !== 'ClickTarget' &&
          instance.dataType !== 'Draggable' &&
          instance.dataType !== 'DropPoint' &&
          instance.dataType !== 'DropArea' &&
          instance.dataType !== 'VisualElement') ||
        !this.targets[instance.uid]
      )
        return;

      const target = this.targets[instance.uid];

      target.hide =
        (instance.fieldValues.find((fieldValue) => fieldValue.field === 'hideInteractableIndicator')?.value as boolean) ?? false;
      target.isCorrect = (instance.fieldValues.find((fieldValue) => fieldValue.field === 'isCorrect')?.value as boolean) ?? false;
      let newName = (instance.fieldValues.find((fieldValue) => fieldValue.field === 'name')?.value as string) ?? instance.uid;
      if (newName === '') newName = instance.uid;
      if (target.name !== newName) {
        target.name = newName;
        this.nameChanged.emit({ uid: instance.uid, name: newName });
      }

      const konvaShape = target.konvaShape as Konva.Shape[];
      const [shape] = konvaShape;
      if (instance.dataType !== 'Draggable' && instance.dataType !== 'VisualElement') {
        shape.stroke(target.hide ? 'black' : target.isCorrect ? 'lime' : 'red');
      }
    });

    this.choices = this.dataService.getEnumTypeResource('PlaceableMedia').slice();

    if (!this.background.dataInstanceUid) {
      return;
    }

    this.currentChoice = this.background.fieldType;
    this.placeableMediaFieldData = { value: this.background.dataInstanceUid } as _FieldData<string>;
  }

  ngAfterViewInit(): void {
    const stageSize = this.resizeStage();

    this.stage = new Konva.Stage({
      container: this.stageContainer.nativeElement,
      width: stageSize.width,
      height: stageSize.height,
    });

    this.stage.add(this.backgroundLayer);

    this.shapeLayer.add(this.transformer);
    this.stage.add(this.shapeLayer);

    const repositionStage = () => {
      if (!this.stage) return;

      const dx = this.scrollContainer.nativeElement.scrollLeft;
      const dy = this.scrollContainer.nativeElement.scrollTop;

      this.stage.container().style.transform = 'translate(' + dx + 'px, ' + dy + 'px)';
      this.stage.x(-dx);
      this.stage.y(-dy);

      this.stagePosition = {
        x: dx,
        y: dy,
      };
    };

    this.scrollContainer.nativeElement.addEventListener('scroll', repositionStage);

    const tooltipLayer = new Konva.Layer({
      width: stageSize.width,
      height: stageSize.height,
    });

    tooltipLayer.add(this.tooltipText);
    this.stage.add(tooltipLayer);

    this.stage.on('click tap', (e) => {
      if (e.target === this.stage) {
        this.transformer.nodes([]);
        this.selected.emit('');
      }
    });

    this.stage.on('mousedown', (e) => {
      this.showContextMenu = undefined;
      if (e.target !== this.editMenuSubject) {
        this.editMenuSubject = undefined;
      }
    });

    this.stage.on('contextmenu', this.onContextMenu.bind(this));

    if (this.currentChoice === 'SolidColorPlaceableMedia' && this.stage) {
      this.backgroundColor = this.background.value;

      const backgroundRect = new Konva.Rect({
        width: this.stageSize.x,
        height: this.stageSize.y,
        id: 'background',
        listening: false,
        opacity: 100,
        fill: this.backgroundColor,
      });

      this.backgroundLayer.add(backgroundRect);
    }

    this.initTargets();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('targets' in changes) {
      const previousValue = changes['targets'].previousValue as Record<string, VisualTarget> | undefined;

      if (previousValue) {
        for (const [id, target] of Object.entries(previousValue)) {
          if (!this.targets[id]) {
            target.konvaShape?.forEach((shape) => shape.destroy());
            target.konvaImage?.destroy();
            this.transformer.nodes([]);
          }
        }
      }

      if (this.stage) this.initTargets();
    }

    if ('canvasRatio' in changes && this.stage) {
      const stageSize = this.resizeStage();
      this.stage.size(stageSize);
    }
  }

  clampShape(shape: Konva.Shape) {
    if (!this.stage) throw new Error('Stage not initialized');

    if (shape instanceof Konva.Circle) {
      shape.x(clamp(shape.x(), shape.radius(), this.stageSize.x - shape.radius()));
      shape.y(clamp(shape.y(), shape.radius(), this.stageSize.y - shape.radius()));
      return;
    }

    shape.x(clamp(shape.x(), shape.offsetX(), this.stageSize.x - shape.width() + shape.offsetX()));
    shape.y(clamp(shape.y(), shape.offsetY(), this.stageSize.y - shape.height() + shape.offsetY()));
  }

  async saveShape(target: VisualTarget) {
    switch (target.type) {
      case ShapeType.RECTANGLE: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        const shape = target.konvaShape[0];
        const size = shape.size();
        const position = shape.position();

        target.size.fieldValue.value = {
          x: (size.width / this.stageSize.x) * this.canvasRatio.x,
          y: (size.height / this.stageSize.y) * this.canvasRatio.y,
        };
        target.position.fieldValue.value = {
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        };

        try {
          await this.dataService.updateFieldValue(target.size.dataInstanceUid, 'size', target.size.fieldValue.value);
          await this.dataService.updateFieldValue(target.position.dataInstanceUid, 'position', target.position.fieldValue.value);
          this.alertService.showAlert('Saved ' + shape.name(), BootstrapClass.INFO);
        } catch (e) {
          console.error('Failed to save rectangle', e);
        }
        break;
      }

      case ShapeType.PIN: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_line, panel, pin] = target.konvaShape;
        const position = pin.position();
        const panelPosition = panel.position();

        target.position.fieldValue.value = {
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        };
        target.panelPosition.fieldValue.value = {
          x: (panelPosition.x / this.stageSize.x) * this.canvasRatio.x,
          y: (panelPosition.y / this.stageSize.y) * this.canvasRatio.y,
        };

        try {
          await this.dataService.updateFieldValue(target.position.dataInstanceUid, 'position', target.position.fieldValue.value);
          await this.dataService.updateFieldValue(
            target.panelPosition.dataInstanceUid,
            'panelPosition',
            target.panelPosition.fieldValue.value,
          );
          this.alertService.showAlert('Saved ' + pin.name(), BootstrapClass.INFO);
        } catch (e) {
          console.error('Failed to save pin', e);
        }
        break;
      }

      case ShapeType.CIRCLE: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        const shape = target.konvaShape[0] as Konva.Circle;
        const radius = shape.radius();
        const position = shape.position();

        target.radius.fieldValue.value = (radius / this.stageSize.x) * this.canvasRatio.x;
        target.position.fieldValue.value = {
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        };

        try {
          await this.dataService.updateFieldValue(target.radius.dataInstanceUid, 'radius', target.radius.fieldValue.value);
          await this.dataService.updateFieldValue(target.position.dataInstanceUid, 'position', target.position.fieldValue.value);
          this.alertService.showAlert('Saved ' + shape.name(), BootstrapClass.INFO);
        } catch (e) {
          console.error('Failed to save circle', e);
        }
        break;
      }
      default:
        console.warn(`Unknown shape type: ${target}`);
        return;
    }
  }

  async updateBackground(url: string, mimeType = 'image/png', name: string) {
    if (!this.stage) throw new Error('Stage not initialized');

    this.currentBackgroundUrl = url;
    this.currentBackgroundMimeType = mimeType;
    this.currentBackgroundName = name;
    this.backgroundImageSize = undefined;

    this.currentBackgroundVideoUrl = undefined;
    this.stage.findOne('#background')?.destroy();

    // Create a rectangle over the full stage
    const rect = new Konva.Rect({
      width: this.stageSize.x,
      height: this.stageSize.y,
      id: 'background',
      listening: false,
      opacity: 0,
    });

    this.backgroundLayer.add(rect);

    const backgroundImages = this.stage.find('#backgroundImage');
    if (backgroundImages) {
      for (const backgroundImage of backgroundImages) {
        backgroundImage.destroy();
      }
    }

    if (mimeType.includes('video')) {
      this.currentBackgroundVideoUrl = url;
      return;
    }

    // Add the image to the rectangle
    let konvaImg: Konva.Image;
    Konva.Image.fromURL(url, (image) => {
      konvaImg = image;
      image.listening(false);
      konvaImg.id('backgroundImage');
      this.backgroundImageSize = { x: image.width(), y: image.height() };
      this.updateImage(image, rect);
      this.backgroundLayer.add(image);
    });
  }

  async adjustCanvasRatio() {
    const width = +this.newCanvasRatioWidth;
    const height = +this.newCanvasRatioHeight;

    if (isNaN(width) || isNaN(height)) {
      console.error('The type of the canvasRatio input must be NUMBER, NUMBER');
      return;
    }

    const activityInstance = this.dataService.getCurrentDataInstances().find((i) => {
      if (!i.fieldValues) return false;
      const backgroundField = i.fieldValues.find((fieldValue) => fieldValue.field === 'background');
      return backgroundField && backgroundField.value === this.backgroundInstance.uid;
    });

    if (!activityInstance) throw new Error('Activity instance not found');

    this.canvasRatio = { x: width, y: height };

    const stageSize = this.resizeStage();
    this.stage?.size(stageSize);

    await this.dataService.updateFieldValue(activityInstance.uid, 'canvasRatio', this.canvasRatio);
  }

  async adjustCanvasToBackground() {
    if (!this.backgroundImageSize) throw new Error('No background image, so impossible to scale');

    if (this.canvasSize.x / this.backgroundImageSize.x < this.canvasSize.y / this.backgroundImageSize.y) {
      const scaleWidth =
        Math.round((((this.canvasSize.y / this.backgroundImageSize.y) * this.backgroundImageSize.x) / this.canvasSize.x) * 100) / 100;
      this.newCanvasRatioWidth = scaleWidth.toString();
      this.newCanvasRatioHeight = '1';
    } else {
      const scaleHeight =
        Math.round((((this.canvasSize.x / this.backgroundImageSize.x) * this.backgroundImageSize.y) / this.canvasSize.y) * 100) / 100;
      this.newCanvasRatioWidth = '1';
      this.newCanvasRatioHeight = scaleHeight.toString();
    }

    await this.adjustCanvasRatio();
  }

  async update() {
    if (!this.background) throw new Error('Background not found');
    if (!this.backgroundInstance) throw new Error('No data instance selected');
    if (!this.currentChoice) throw new Error('No enum type selected');

    this.loading = true;

    // Create a new instance
    const instance = await this.dataService.initStruct(this.currentChoice);
    const activityInstance = this.dataService.getCurrentDataInstances().find((i) => {
      const backgroundField = i.fieldValues.find((fieldValue) => fieldValue.field === 'background');
      return backgroundField && backgroundField.value === this.backgroundInstance.uid;
    });

    if (!activityInstance) throw new Error('Activity instance not found');

    const backgroundField = activityInstance.fieldValues.find((fieldValue) => fieldValue.field === 'background');
    if (!backgroundField) throw new Error('Background field not found');

    backgroundField.value = instance.uid;
    await this.dataService.updateFieldValue(activityInstance.uid, 'background', instance.uid);

    try {
      await this.dataService.deleteDataInstance(this.backgroundInstance);
    } catch (e) {
      console.warn('Failed to delete old instance', e);
    }

    switch (true) {
      case this.currentChoice.includes('Video'):
        this.background.fieldId = 'video';
        this.background.fieldType = 'VideoRef';
        this.background.name = 'Video';
        break;

      case this.currentChoice.includes('Image'):
        this.background.fieldId = 'image';
        this.background.fieldType = 'ImageRef';
        this.background.name = 'Image';
        break;

      case this.currentChoice.includes('Color'):
        this.background.fieldId = 'color';
        this.background.fieldType = 'Color';
        this.background.name = 'Color';
        break;
    }

    this.background.dataInstanceUid = instance.uid;
    this.background.value = this.background.fieldId === 'color' ? '#00000000' : '';
    this.backgroundColor = this.background.value;
    this.backgroundInstance = instance;
    this.placeableMediaFieldData = { value: this.background.dataInstanceUid } as _FieldData<string>;

    this.loading = false;
    await this.updateBackground('', 'image/png', '');

    this.showAlert();
  }

  showAlert() {
    if (!this.background) return;
    this.alertService.showAlert('Updated ' + this.background.name + '...', BootstrapClass.INFO);
  }

  onAddPreset() {
    if (!this.selectedPreset) return;
    this.addPreset.emit(this.selectedPreset);
  }

  async onBackgroundColorChange(color: string) {
    if (!this.stage) throw new Error('Stage not initialized');

    this.backgroundColor = color;

    this.stage.findOne('#background')?.destroy();
    // Create a rectangle over the full stage
    const rect = new Konva.Rect({
      width: this.stageSize.x,
      height: this.stageSize.y,
      id: 'background',
      listening: false,
      opacity: 100,
      fill: this.backgroundColor,
    });
    this.backgroundLayer.add(rect);

    const backgroundImages = this.stage.find('#backgroundImage');
    if (backgroundImages) {
      for (const backgroundImage of backgroundImages) {
        backgroundImage.destroy();
      }
    }

    this.background.value = this.backgroundColor;

    if (!this.backgroundInstance) throw new Error('Activity instance not found');
    const backgroundField = this.backgroundInstance.fieldValues.find((fieldValue) => fieldValue.field === 'color');
    if (!backgroundField) throw new Error('BackgroundColor field not found');
    backgroundField.value = this.backgroundColor;
    await this.dataService.updateFieldValue(this.backgroundInstance.uid, 'color', this.backgroundColor);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.routeSub?.unsubscribe();
    this.instanceUpdatedSub?.unsubscribe();

    if (this.stage) this.stage.destroy();

    this.shapeLayer.destroy();
    this.backgroundLayer.destroy();
    this.transformer.destroy();
  }

  protected runContextMenuAction(action: (x: number, y: number) => void) {
    action(this.mousePosition.x, this.mousePosition.y);
    this.showContextMenu = undefined;
  }

  private createRectangle(name: string, uid: string, size: Vector2, position: Vector2, image?: DataInstanceFieldData<string>): Konva.Rect {
    const rect = new Konva.Rect({
      // Convert the width and height to pixels
      width: size.x * this.canvasSize.x,
      height: size.y * this.canvasSize.y,
      x: position.x * this.canvasSize.x,
      y: position.y * this.canvasSize.y,
      fill: 'transparent',
      stroke: 'red',
      strokeWidth: 4,
      draggable: true,
      strokeScaleEnabled: false,
      dash: [10, 5],
      shadowBlur: 10,
      cornerRadius: 5,
      name: name,
      listening: true,
      transformable: true,
    });

    if (this.visualElementsData[uid]) {
      rect.stroke('blue');
    }

    if (image) {
      this.initImage(rect, this.targets[uid], { image });
    }

    return rect;
  }

  private createCircle(
    name: string,
    uid: string,
    radius: number,
    position: Vector2,
    image: DataInstanceFieldData<string> | undefined,
  ): Konva.Circle {
    const circle = new Konva.Circle({
      radius: radius * this.canvasSize.x,
      fill: 'transparent',
      stroke: 'red',
      strokeWidth: 4,
      draggable: true,
      strokeScaleEnabled: false,
      dash: [10, 5],
      shadowBlur: 10,
      name: name,
      x: position.x * this.canvasSize.x,
      y: position.y * this.canvasSize.y,
      transformable: true,
    });

    if (this.visualElementsData[uid]) {
      circle.stroke('blue');
    }

    if (image) {
      this.initImage(circle, this.targets[uid], { image });
    }

    return circle;
  }

  private createPin(name: string, position: Vector2, panelPosition: Vector2) {
    const panel = new Konva.Rect({
      width: 100,
      height: 100,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 2,
      draggable: true,
      name: name,
      x: panelPosition.x * this.stageSize.x,
      y: panelPosition.y * this.stageSize.y,
      offset: {
        x: 50,
        y: 100,
      },
    });

    const pin = new Konva.Circle({
      radius: 10,
      fill: 'red',
      stroke: 'black',
      strokeWidth: 2,
      draggable: true,
      name: name,
      x: position.x * this.stageSize.x,
      y: position.y * this.stageSize.y,
    });

    const line = new Konva.Line({
      points: [0, 0, 0, 0],
      stroke: 'black',
      strokeWidth: 2,
      lineCap: 'round',
      lineJoin: 'round',
      dash: [10, 5],
      name: name,
    });

    const updateLine = () => {
      line.points([pin.x(), pin.y(), panel.x(), panel.y()]);
    };

    updateLine();

    pin.on('dragmove', updateLine);
    panel.on('dragmove', updateLine);

    return [line, panel, pin];
  }

  private createShape(id: string, target: VisualTarget) {
    const createdShapes: Konva.Shape[] = [];

    switch (target.type) {
      case ShapeType.RECTANGLE: {
        const { size, position, media } = target;
        createdShapes.push(this.createRectangle(target.name, target.uid, size.fieldValue.value, position.fieldValue.value, media));
        break;
      }

      case ShapeType.CIRCLE: {
        const { radius, position, media } = target;
        createdShapes.push(this.createCircle(target.name, target.uid, radius.fieldValue.value, position.fieldValue.value, media));
        break;
      }

      case ShapeType.PIN: {
        const { position, panelPosition } = target;
        createdShapes.push(...this.createPin(target.name, position.fieldValue.value, panelPosition.fieldValue.value));
        break;
      }

      default:
        console.warn(`Unknown shape: ${target}`);
        return;
    }

    for (const shape of createdShapes) {
      // position and sizes
      shape.on('dragmove', this.clampShape.bind(this, shape));
      shape.on('dragend transformend updatedFromMenuSubject', this.saveShape.bind(this, target));
      shape.on('transform', () => {
        shape.width(Math.max(5, shape.width() * shape.scaleX()));
        shape.height(Math.max(5, shape.height() * shape.scaleY()));
        shape.scaleX(1);
        shape.scaleY(1);
      });

      // tooltip
      shape.on('mouseenter', () => {
        this.tooltipText.text(target.name || shape.name());
        this.tooltipText.fill(shape.stroke());
        this.tooltipText.show();
      });

      shape.on('mouseleave', () => {
        this.tooltipText.hide();
      });

      const onMouseMove = debounce(() => {
        const position = this.stage?.getPointerPosition();
        if (position) {
          this.tooltipText.position({
            x: position.x + this.stagePosition.x,
            y: position.y + this.stagePosition.y,
          });
        }
      });
      shape.on('mousemove', onMouseMove);

      // enable transformer
      shape.on('click tap', () => {
        if (shape.getAttr('transformable')) {
          this.transformer.nodes([shape]);
        } else {
          this.transformer.nodes([]);
        }
        this.selected.emit(id);
      });
    }

    this.shapeLayer.add(...createdShapes);
    this.selected.emit(id);
    this.shapeCreated.emit(createdShapes);

    return createdShapes;
  }

  private resizeStage() {
    const { width, height } = environment.aspectRatio;

    const ratio = height / width;
    this.canvasSize = ratio > 1 ? { x: 450, y: 800 } : { x: 800, y: 450 };
    this.canvasOrientation = ratio > 1 ? Orientation.Vertical : Orientation.Horizontal;

    const canvasWidth = this.canvasSize.x * this.canvasRatio.x;
    const canvasHeight = this.canvasSize.y * this.canvasRatio.y;

    this.stageSize = {
      x: canvasWidth,
      y: canvasHeight,
    };

    this.stageParent.nativeElement.style.width = canvasWidth + 'px';
    this.stageParent.nativeElement.style.height = canvasHeight + 'px';

    this.newCanvasRatioWidth = this.canvasRatio.x.toString();
    this.newCanvasRatioHeight = this.canvasRatio.y.toString();

    if (this.currentBackgroundUrl) {
      this.updateBackground(this.currentBackgroundUrl, this.currentBackgroundMimeType, this.currentBackgroundName).then();
    }

    return {
      width: this.canvasSize.x,
      height: this.canvasSize.y,
    };
  }

  private onContextMenu(konvaEventObject: Konva.KonvaEventObject<MouseEvent>) {
    konvaEventObject.evt.preventDefault();

    this.showContextMenu = undefined;

    if (konvaEventObject.target !== this.stage) {
      this.editMenuSubject = konvaEventObject.target as Konva.Shape;
      return;
    }

    const pos = this.stage.getRelativePointerPosition();
    if (!pos) {
      return;
    }

    this.mousePosition = {
      x: (pos.x / this.stageSize.x) * this.canvasRatio.x,
      y: (pos.y / this.stageSize.y) * this.canvasRatio.y,
    };

    const visualEditor = document.getElementById('visual-editor');
    if (!visualEditor) return;

    // set the context menu position relative to the editor, ignoring scroll
    this.showContextMenu = pos;
  }

  private initTargets() {
    if (!this.stage) {
      console.warn("Can't init targets, stage is undefined");
      return;
    }

    for (const [id, target] of Object.entries(this.targets)) {
      if (!target.konvaShape) {
        target.konvaShape = this.createShape(id, target);
        const konvaShape = target.konvaShape as Konva.Shape[];
        const [shape] = konvaShape;
        shape.stroke(target.hide ? 'black' : target.isCorrect ? 'lime' : 'red');
        if (target.konvaShape && target.targetType === TargetType.VISUAL_ELEMENT) {
          shape.stroke('blue');
        } else if (target.konvaShape && target.targetType === TargetType.DRAGGABLE) {
          shape.stroke('yellow');
        }
      }
    }

    this.editMenuSubject = undefined;
  }

  private initImage(
    linkedShape: Konva.Shape,
    linkedTarget: VisualTarget,
    {
      image,
    }: {
      image: DataInstanceFieldData<string>;
    },
  ) {
    let konvaImg: Konva.Image;
    linkedShape.on('transformend dragmove', () => this.updateImage(konvaImg, linkedShape));

    const sub = this.dataService.instanceUpdated$
      .pipe(
        filter((instance) => !!instance && instance.uid === image.dataInstanceUid),
        map((instance) => {
          if (instance) {
            const imageField = instance.fieldValues.find((fieldValue) => fieldValue.field === 'image');
            if (imageField) return imageField.value as string;
            const videoField = instance.fieldValues.find((fieldValue) => fieldValue.field === 'video');
            if (videoField) return videoField.value as string;
          }
          return undefined;
        }),
        startWith(image.fieldValue.value),
        distinctUntilChanged(),
        switchMap((imageUid) =>
          this.dataService.fileUploaded$.pipe(
            filter((file) => file.uid === imageUid),
            map(() => imageUid),
            startWith(imageUid),
          ),
        ),
      )
      .subscribe((imageUid) => {
        if (konvaImg) {
          konvaImg.destroy();
          linkedTarget.konvaImage = undefined;
        }

        if (imageUid) {
          this.dataService.downloadFile(imageUid).then((blob) => {
            const url = URL.createObjectURL(blob);

            // TODO: I think the extremely long image load times is because of this function.
            Konva.Image.fromURL(url, (image) => {
              konvaImg = image;
              image.listening(false);

              this.shapeLayer.add(image);
              linkedTarget.konvaImage = image;
              this.updateImage(image, linkedShape);

              URL.revokeObjectURL(url);
            });
          });
        }
      });

    this.subscriptions.push(sub);
  }

  private updateImage(image: Konva.Image, shape: Konva.Shape) {
    if (!image) return;

    const isCircle = shape instanceof Konva.Circle;

    // center image in the shape, keep image aspect ratio
    const w = shape.width() * shape.scaleX();
    const h = shape.height() * shape.scaleY();
    const scale = Math.min(w / image.width(), h / image.height());

    image.scale({ x: scale, y: scale });
    image.position({
      x: isCircle ? shape.x() : shape.x() + w / 2,
      y: isCircle ? shape.y() : shape.y() + h / 2,
    });

    image.offset({
      x: image.width() / 2,
      y: image.height() / 2,
    });
  }
}
