import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DataInstance } from '../../../../models/data/DataInstance';
import { lastValueFrom, Subscription } from 'rxjs';
import { DataService } from '../../../../_services/data-management/data.service';
import { FlowchartNode } from '../../../../models/data/FlowchartNode';
import { Resource } from '../../../../models/data/Resource';
import { FieldValue } from '../../../../models/data/FieldValue';
import { FieldData } from '../../../../models/data/FieldData';
import { Field } from '../../../../models/schema/Field';
import { environment } from '../../../../../environments/environment';
import { generateRandomString, sleep } from '../../../../_services/utils';
import { LoadingScreenService } from '../../../../_services/UI-elements/loading-screen.service';
import { NodePosition } from '../../../../models/schema/NodePosition';
import { HTTPRequestService } from '../../../../_services/data-management/HTTP-request.service';

@Component({
  selector: 'app-mission-editor',
  templateUrl: './mission-editor.component.html',
  styleUrls: ['./mission-editor.component.scss'],
})
export class MissionEditorComponent implements OnInit, OnDestroy {
  @Output() goToHomePageEvent = new EventEmitter<void>();

  missionInfoUid?: string;
  missionInfoFieldData?: FieldData<string>;
  moduleId?: string;
  activityInstanceUid?: string;
  loading = false;
  data: DataInstance[] = [];
  missionData?: DataInstance;
  missionInfo?: DataInstance;
  missionName?: string;
  currentActivity?: FlowchartNode = undefined;
  activities: FlowchartNode[] = [];
  selectableActivities: Resource[] = [];
  startActivityField?: FieldData<string>;
  activityTypes: Resource[] = [];
  routerSubscription?: Subscription;
  activitiesSubscription?: Subscription;
  saveButtonSubscription?: Subscription;
  currentActivitySubscription?: Subscription;

  protected readonly environment = environment;
  private routeParamsSub?: Subscription;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private requestService: HTTPRequestService,
  ) {}

  async ngOnInit() {
    this.routeParamsSub = this.activatedRoute.params.subscribe(async (params) => {
      this.missionInfoUid = params['missionInfoUid'];
      this.missionInfoFieldData = undefined;
      this.loading = true;
      await this.loadMission();
      if (this.missionInfoUid)
        this.missionInfoFieldData = {
          dataInstanceUid: '',
          fieldId: '',
          fieldType: '',
          name: '',
          description: '',
          value: this.missionInfoUid,
        };
      this.loading = false;
    });

    this.routerSubscription = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.activityInstanceUid = params['activity'];
      while (this.loading) {
        await sleep(100);
      }
      this.currentActivity = this.activities.find((a) => a.dataInstanceUid === this.activityInstanceUid);
      if (this.activityInstanceUid) {
        this.dataService.currentActivityChanged.next(this.currentActivity);
      } else {
        this.dataService.currentActivityChanged.next(undefined);
      }
    });

    this.activitiesSubscription = this.dataService.activitiesUpdated$.subscribe((activities: FlowchartNode[]) => {
      this.activities = activities;
      this.selectableActivities = activities.map((a) => ({ value: a.dataInstanceUid, name: a.name }));
    });

    this.currentActivitySubscription = this.dataService.currentNodeChanged$.subscribe((activity) => {
      if (this.loading) return;
      if (!activity) {
        this.router.navigate([], { queryParams: { activity: null } }).then();
      } else {
        this.router.navigate([], { queryParams: { activity: activity.dataInstanceUid } }).then();
      }
    });
  }

  getFieldData<T>(field: Field, dataInstanceUid: string, fieldValue?: FieldValue<T>): FieldData<T> {
    return {
      dataInstanceUid: dataInstanceUid,
      fieldId: field.fieldId,
      fieldType: field.type,
      name: field.name,
      fieldEditor: field.fieldEditor,
      description: field.description,
      value: fieldValue?.value as T,
    };
  }

  ngOnDestroy() {
    this.activitiesSubscription?.unsubscribe();
    this.currentActivitySubscription?.unsubscribe();
    this.saveButtonSubscription?.unsubscribe();
    this.routeParamsSub?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  async loadMission() {
    await this.loadingScreenService.show(async () => {
      if (!this.missionInfoUid) return console.error('No missionInfoUid provided');

      this.missionInfo = await this.dataService.loadMission(this.missionInfoUid);
      const missionUid = this.tryGetMissionUid();
      this.missionName = this.missionInfo?.fieldValues.find((fieldValue) => fieldValue.field === 'name')?.value as string;

      if (missionUid) {
        this.missionData = await this.dataService.getDataInstance(missionUid);
        if (!this.missionData) throw new Error('Mission data not found');

        const startActivityFieldValue = this.missionData?.fieldValues.find((fieldValue) => fieldValue.field === 'startActivity');
        if (!startActivityFieldValue || typeof startActivityFieldValue.value !== 'string') throw new Error('Start activity not found');

        const startActivityField = this.dataService.getField('startActivity', 'Mission');
        this.startActivityField = this.getFieldData<string>(
          startActivityField,
          this.missionData.uid,
          startActivityFieldValue as FieldValue<string>,
        );

        try {
          const module = await this.dataService.getModuleBelongingToMission('Module', 'List<StructRef<MissionInfo>>', this.missionInfoUid);
          if (module) this.moduleId = module.fieldValues.find((fieldValue) => fieldValue.field === 'moduleId')?.value as string;
        } catch (e) {
          console.log('Could not find module for mission', e);
        }
      }

      this.activityTypes = await this.initializeActivityTypes();
    });
  }

  // onAddActivity without name
  onAddActivity(activityTypeId: string) {
    return this.loadingScreenService.show(async () => {
      const newActivity = await this.dataService.initStruct(activityTypeId);
      await this.dataService.updateFieldValue(newActivity.uid, 'name', newActivity.dataType + '_' + generateRandomString(10));

      // Update the activities fieldValue of the current mission
      if (!this.missionInfo) return;

      const missionUid = this.tryGetMissionUid();
      if (!missionUid) return;

      this.missionData = await this.dataService.getDataInstance(missionUid);

      if (this.missionData) {
        for (const fieldValue of this.missionData.fieldValues) {
          if (fieldValue.field === 'activities') {
            if (fieldValue.value && Array.isArray(fieldValue.value)) fieldValue.value.push(newActivity.uid);
            else fieldValue.value = [newActivity.uid];
          }
        }

        await this.dataService.updateDataInstance(this.missionData);
        await this.dataService.loadMission(this.missionInfo.uid);
      }
    });
  }

  async deleteSelectedNodes(activityInstanceUids: string[]) {
    await this.loadingScreenService.show(async () => {
      await this.dataService.deleteDataInstances(activityInstanceUids);
      await this.loadMission();
      return await this.router.navigate(['home/mission/' + this.missionInfoUid], { queryParams: { activity: null } });
    });
  }

  async duplicateSelectedNodes(activityInstanceUids: string[], isActivityFromOtherMission = false) {
    await this.loadingScreenService.show(async () => {
      if (!this.missionData) return console.warn('Mission data not found');

      const missionActivities = this.missionData.fieldValues.find((fieldValue) => fieldValue.field === 'activities');
      if (!missionActivities) return console.warn('Mission activities field not found');

      const duplicatedDataInstancesData = isActivityFromOtherMission
        ? await this.dataService.duplicateDataInstance(activityInstanceUids, ['EnumRef<Activity>'])
        : await this.dataService.duplicateDataInstance(activityInstanceUids);
      if (!duplicatedDataInstancesData || duplicatedDataInstancesData.dataInstances.length === 0)
        return console.warn('Could not duplicate activity');
      for (const newActivityInstance of duplicatedDataInstancesData.dataInstances) {
        (missionActivities.value as string[]).push(newActivityInstance.uid);

        const originalActivityUid = Object.entries(duplicatedDataInstancesData.originalToDuplicateMapping).find(
          ([_key, value]) => value === newActivityInstance.uid,
        )?.[0];
        if (!originalActivityUid) return console.warn('Could not find original activity uid for duplicated activity');

        let nodePosition: NodePosition;
        try {
          if (!isActivityFromOtherMission) {
            nodePosition = await lastValueFrom(this.requestService.getNodePosition(this.dataService.currentGameId, originalActivityUid));
            await lastValueFrom(
              this.requestService.updateNodePosition(this.dataService.currentGameId, newActivityInstance.uid, {
                x: nodePosition.positionX + 50,
                y: nodePosition.positionY + 50,
              }),
            );
          } else {
            // For activities from another mission we set the coords to 0;0 so that they will get moved to the view of the user
            await lastValueFrom(
              this.requestService.updateNodePosition(this.dataService.currentGameId, newActivityInstance.uid, {
                x: 0,
                y: 0,
              }),
            );
          }
        } catch (e) {
          console.warn('Could not load node position for activity with uid: ' + originalActivityUid + ', falling back to 0;0');
          await lastValueFrom(
            this.requestService.updateNodePosition(this.dataService.currentGameId, newActivityInstance.uid, {
              x: 0,
              y: 0,
            }),
          );
        }
      }

      await this.dataService.updateFieldValue(this.missionData.uid, 'activities', missionActivities.value);

      await this.dataService.loadMission(this.missionInfoUid!);
    });
  }

  async initializeActivityTypes(): Promise<Resource[]> {
    const activityTypes: Resource[] = [];
    const activityEnum = this.dataService.getEnumType('Activity');

    for (const structTypeId of activityEnum.options) {
      activityTypes.push({ value: structTypeId, name: this.dataService.getStructType(structTypeId).name });
    }

    return activityTypes;
  }

  openOverview() {
    this.dataService.currentNodeChanged.next(undefined);
  }

  private tryGetMissionUid(): string | undefined {
    if (!this.missionInfo) return undefined;
    return this.missionInfo?.fieldValues.find((fieldValue) => fieldValue.field === 'mission')?.value as string | undefined;
  }
}
