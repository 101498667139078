import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldComponent } from './_components/dynamic-fields/field/field.component';
import { DynamicFieldDirective } from './_components/dynamic-fields/dynamic-field.directive';
import { TextInputFieldComponent } from './_components/dynamic-fields/primitive-fields/text-input-field/text-input-field.component';
import { ChatFieldComponent } from './_components/dynamic-fields/non-primitive-fields/chat-field/chat-field.component';
import { DropdownFieldComponent } from './_components/dynamic-fields/primitive-fields/dropdown-field/dropdown-field.component';
import { NgOptimizedImage } from '@angular/common';
import { TextAreaFieldComponent } from './_components/dynamic-fields/primitive-fields/text-area-field/text-area-field.component';
import { CheckFieldComponent } from './_components/dynamic-fields/primitive-fields/check-field/check-field.component';
import { DataService } from './_services/data-management/data.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './_components/home-screen/login/login.component';
import { HomeScreenComponent } from './_components/home-screen/home-screen.component';
import { FileUploadComponent } from './_components/dynamic-fields/primitive-fields/file-upload/file-upload.component';
import { FlowchartComponent } from './_components/editors/flowchart/flowchart.component';
import { ConvertService } from './_services/convert-service';
import { NumberInputFieldComponent } from './_components/dynamic-fields/primitive-fields/number-input-field/number-input-field.component';
import { ImageFieldComponent } from './_components/dynamic-fields/non-primitive-fields/image-field/image-field.component';
import { IconFieldComponent } from './_components/dynamic-fields/primitive-fields/icon-field/icon-field.component';
import { ModuleEditorComponent } from './_components/editors/module/module-editor/module-editor.component';
import { MissionListComponent } from './_components/home-screen/mission-list/mission-list.component';
import { ModuleListComponent } from './_components/home-screen/module-list/module-list.component';
import { ResourceListComponent } from './_components/home-screen/resource-list/resource-list.component';
import { SelectorFieldComponent } from './_components/dynamic-fields/non-primitive-fields/selector-field/selector-field.component';
import { ConfirmationModalComponent } from './_components/UI-elements/confirmation-modal/confirmation-modal.component';
import { FlowchartNodeComponent } from './_components/editors/module/flowchart-node/flowchart-node.component';
import { SelectTypeSelectorComponent } from './_components/dynamic-fields/primitive-fields/select-type-selector/select-type-selector.component';
import { InlineListEditorComponent } from './_components/editors/inline-list-editor/inline-list-editor.component';
import { StructInstanceEditorComponent } from './_components/editors/struct-instance-editor/struct-instance-editor.component';
import { HTTPRequestService } from './_services/data-management/HTTP-request.service';
import { ActivityComponent } from './_components/editors/mission/activity/activity.component';
import { MissionEditorComponent } from './_components/editors/mission/mission-editor/mission-editor.component';
import { ChatComponent } from './_components/editors/mission/activity/chat/chat.component';
import { AlertComponent } from './_components/UI-elements/alert/alert.component';
import { VisualEditorComponent } from './_components/editors/visual-editor/visual-editor.component';
import { FieldDirective } from './_components/dynamic-fields/field/field.directive';
import { CanvasEditorComponent } from './_components/editors/canvas-editor/canvas-editor.component';
import { MapEditorComponent } from './_components/editors/map/map-editor/map-editor.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AudioFieldComponent } from './_components/dynamic-fields/non-primitive-fields/audio-field/audio-field.component';
import { SchemaListComponent } from './_components/schema-editor/schema-list/schema-list.component';
import { SchemaTableComponent } from './_components/schema-editor/schema-table/schema-table.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './_services/authorization/token.interceptor';
import { ResourceSelectorFieldComponent } from './_components/dynamic-fields/non-primitive-fields/resource-selector-field/resource-selector-field.component';
import { EnumInstanceEditorComponent } from './_components/editors/enum-instance-editor/enum-instance-editor.component';
import { VideoFieldComponent } from './_components/dynamic-fields/non-primitive-fields/video-field/video-field.component';
import { VariableListComponent } from './_components/home-screen/variable-list/variable-list.component';
import { VariableEditorComponent } from './_components/editors/variable/variable-editor/variable-editor.component';
import { ColorFieldComponent } from './_components/dynamic-fields/primitive-fields/color-field/color-field-component';
import { ColorPickerModule } from 'ngx-color-picker';
import { VariableComparisonComponent } from './_components/dynamic-fields/non-primitive-fields/variable-comparison/variable-comparison.component';
import { SetVariableFieldComponent } from './_components/dynamic-fields/non-primitive-fields/set-variable-field/set-variable-field.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MenuSubjectPipe } from './_components/editors/visual-editor/menu-subject.pipe';
import { MaintenanceScreenComponent } from './_components/maintenance-screen/maintenance-screen.component';
import { PrettifyNamePipe } from './_services/UI-elements/prettify-name.pipe';
import { SearchPopoverComponent } from './_components/search-popover/search-popover.component';
import { SpinnerComponent } from './_components/spinner/spinner.component';
import { DuplicationCompleteModalComponent } from './_components/UI-elements/duplication-complete-modal/duplication-complete-modal.component';
import { ActivityNodeComponent } from './rete/activity-node/activity-node.component';
import { ReteModule } from 'rete-angular-plugin/17';
import { SocketComponent } from './rete/socket/socket.component';
import { KennisNodeComponent } from './rete/kennis-node/kennis-node.component';
import { ReplacePipe } from './_services/UI-elements/replace.pipe';
import { TextWithAudioComponent } from './_components/editors/text-with-audio/text-with-audio.component';
import { ConnectionComponent } from './rete/connection/connection.component';
import { VectorFieldComponent } from './_components/dynamic-fields/primitive-fields/vector-field/vector-field.component';
import { SavedChangesCheckerGuard } from './_services/data-management/saved-changes-checker.guard';

@NgModule({
  declarations: [
    AppComponent,
    TextInputFieldComponent,
    FieldComponent,
    DynamicFieldDirective,
    ActivityComponent,
    FlowchartComponent,
    ChatFieldComponent,
    DropdownFieldComponent,
    TextAreaFieldComponent,
    CheckFieldComponent,
    MissionEditorComponent,
    LoginComponent,
    HomeScreenComponent,
    FileUploadComponent,
    ChatComponent,
    NumberInputFieldComponent,
    ImageFieldComponent,
    IconFieldComponent,
    ModuleEditorComponent,
    MissionListComponent,
    ModuleListComponent,
    ResourceListComponent,
    SelectorFieldComponent,
    ConfirmationModalComponent,
    FlowchartNodeComponent,
    StructInstanceEditorComponent,
    SelectTypeSelectorComponent,
    InlineListEditorComponent,
    AlertComponent,
    VisualEditorComponent,
    FieldDirective,
    CanvasEditorComponent,
    ResourceSelectorFieldComponent,
    MapEditorComponent,
    AudioFieldComponent,
    SchemaListComponent,
    SchemaTableComponent,
    AudioFieldComponent,
    MenuSubjectPipe,
    EnumInstanceEditorComponent,
    VideoFieldComponent,
    VariableListComponent,
    VariableEditorComponent,
    VariableComparisonComponent,
    ColorFieldComponent,
    SetVariableFieldComponent,
    MaintenanceScreenComponent,
    ActivityNodeComponent,
    KennisNodeComponent,
    SocketComponent,
    ConnectionComponent,
    TextWithAudioComponent,
    VectorFieldComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    NgbPopover,
    CdkDropList,
    CdkDrag,
    NgOptimizedImage,
    ColorPickerModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    PrettifyNamePipe,
    SearchPopoverComponent,
    SpinnerComponent,
    DuplicationCompleteModalComponent,
    CdkDragHandle,
    ReteModule,
    ReplacePipe,
  ],
  providers: [
    DataService,
    HTTPRequestService,
    ConvertService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    SavedChangesCheckerGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
