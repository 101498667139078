<section class="vh-100 gradient-custom">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-4 border-0 shadow-sm overflow-hidden">
          <div class="row g-0">
            <div class="col-md-6 col-lg-6 d-flex align-items-center">
              <div class="card-body p-md-5 mx-md-4">
                <div class="text-center">
                  <img alt="logo" height="200" ngSrc="/assets/images/orange.png" width="200" />
                  <h4 class="mt-1 mb-5 pb-1">Welcome to CAS</h4>
                </div>

                <form (ngSubmit)="onLogin(username.value, password.value)">
                  <p>Please login to your account</p>

                  <div class="form-outline mb-4">
                    <input #username class="form-control" placeholder="Username or email address" type="email" />
                  </div>

                  <div class="form-outline mb-4">
                    <input #password class="form-control" placeholder="Password" type="password" />
                  </div>

                  <div *ngIf="wrongCredentials" class="error">Login failed. Please check your credentials.</div>
                  <div *ngIf="loginFailed" class="error">Something went wrong. Please try again later.</div>
                  <div *ngIf="invalidRole" class="error">You do not have the required role to access this application.
                  </div>

                  <div class="text-center pt-1 mb-5 pb-1">
                    <button class="btn btn-primary form-control" type="submit">Log in</button>
                  </div>
                </form>
              </div>
            </div>

            <div class="col-md-6 col-lg-6 d-none d-md-block">
              <img
                alt="login form"
                class="w-100 h-100 object-fit-cover"
                height="1018"
                ngSrc="../../../../assets/images/unityBad.png"
                priority
                width="820" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
