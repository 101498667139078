<!-- The mission tree screen -->
<div class="mission-tree">
  <app-flowchart (addActivity)="onAddActivity($event)" (deleteActivities)="deleteSelectedNodes($event)"
                 (duplicateActivities)="duplicateSelectedNodes($event)"
                 (pasteActivities)="duplicateSelectedNodes($event, true)"
                 [flowchartType]="'mission'"/>
</div>

<!--Buttons to save and add new activities -->
<div class="d-flex justify-content-center align-items-center gap-2 m-2">
  <p class="h4 mb-0 me-2">{{ missionName }}</p>

  <button (click)="openOverview()" class="btn btn-primary">
    <i class="bi bi-info-circle"></i> Overview
  </button>

  <div *ngIf="startActivityField">Start activity:</div>
  <div *ngIf="startActivityField">
    <app-resource-selector-field [data]="startActivityField"></app-resource-selector-field>
  </div>

  <a
    *ngIf="environment.playGameUrl && missionInfo"
    class="btn btn-primary"
    href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfo.uid }}{{moduleId ? '&module='+moduleId:''}}"
    target="_blank"
  >
    <i class="bi bi-play-circle"></i> Play from start
  </a>

  <a
    *ngIf="environment.playGameUrl && missionInfo && activityInstanceUid"
    class="btn btn-primary"
    href="{{ environment.playGameUrl }}?offline=1&mission={{ missionInfo.uid }}&activity={{ activityInstanceUid }}{{moduleId ? '&module='+moduleId:''}}"
    target="_blank"
  >
    <i class="bi bi-play-circle"></i> Play from current activity
  </a>
</div>


<!--The editor where the current activity or the overview of the mission can be added-->
<div class="container mt-2 pb-2">
  <app-activity
    *ngIf="currentActivity"
    [uid]="currentActivity.dataInstanceUid"
  />

  <app-struct-instance-editor
    *ngIf="!currentActivity && missionInfoFieldData"
    [data]="missionInfoFieldData"></app-struct-instance-editor>
</div>
