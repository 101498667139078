import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataInstance } from '../../../../models/data/DataInstance';
import { DataService } from '../../../../_services/data-management/data.service';
import { Router } from '@angular/router';
import { Variable } from '../../../../models/schema/Variable';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { AlertService } from '../../../../_services/UI-elements/alert-service';
import { VariableType } from '../../../../models/schema/VariableType';

@Component({
  selector: 'app-set-variable-field',
  templateUrl: './set-variable-field.component.html',
  styleUrls: ['./set-variable-field.component.scss'],
})
export class SetVariableFieldComponent implements OnInit {
  @Input() instance: DataInstance | undefined;

  @Output() deleteSetVariable: EventEmitter<void> = new EventEmitter<void>();

  variables: Variable[] = [];
  variable = '';
  newValue: string | number | boolean = '';
  currentVariable?: Variable;

  protected readonly String = String;
  protected readonly VariableType = VariableType;

  constructor(
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    if (!this.instance) return;

    const allVariables = await this.dataService.getAllVariables();
    if (allVariables) this.variables = allVariables;

    if (!this.instance.fieldValues) {
      console.warn('No field values found on instance', this.instance);
      return;
    }

    for (const field of this.instance.fieldValues) {
      // TODO: This will cause unexpected cases if there is more than one field with the field of "variable"
      if (field.field === 'variable') {
        this.variable = field.value as string;
      }
    }

    if (!this.variable) {
      console.warn('No variable found on instance', this.instance);
      return;
    }

    this.currentVariable = await this.dataService.getVariable(this.variable);

    if (!this.currentVariable) {
      throw new Error('Variable not found');
    }

    // Small optimization to check the map first
    const field = this.instance.fieldValuesMap
      ? this.instance.fieldValuesMap['newValue']
      : this.instance.fieldValues.find((field) => field.field === 'newValue');

    if (field) {
      this.newValue = this.parseVariable(this.currentVariable.valueType, field.value as string);
    }
  }

  async onVariableChange() {
    if (!this.instance) throw new Error('Instance not found');

    const newVariable = await this.dataService.getVariable(this.variable);
    if (!newVariable) throw new Error('Variable not found');

    await this.dataService.updateFieldValue(this.instance?.uid, 'variable', this.variable);
    this.currentVariable = newVariable;

    switch (this.currentVariable.valueType) {
      case VariableType.String:
        this.newValue = '';
        await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', '');
        break;
      case VariableType.Number:
        this.newValue = 0;
        await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', '0');
        break;
      case VariableType.Boolean:
        this.newValue = false;
        await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', 'false');
        break;
      case VariableType.Color:
        this.newValue = '#00000000';
        await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', '#00000000');
        break;
    }

    this.showUpdatingAlert();
  }

  async onAddNewVariable() {
    if (!this.instance) throw new Error('Instance not found');
    const newVariable = await this.dataService.initVariable();
    // We are not setting the fieldValue to the variable ref as it will be removed when you set the type of the variable
    // That removes all refernces to the variable including the one
    this.router.navigate(['/home/variable', newVariable.variableRef]).then();
  }

  onDelete() {
    this.deleteSetVariable.emit();
  }

  onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    this.router.navigate(['/home/variable/' + variable.variableRef]).then();
  }

  async updateString() {
    if (!this.instance) throw new Error('Instance not found');
    await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', this.newValue);
    this.showUpdatingAlert();
  }

  async updateNumber() {
    if (!this.instance) throw new Error('Instance not found');
    this.newValue = Math.round(this.newValue as number);
    await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', this.newValue.toString());
    this.showUpdatingAlert();
  }

  async updateBoolean() {
    if (!this.instance) throw new Error('Instance not found');
    await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', this.newValue.toString());
    this.showUpdatingAlert();
  }

  async updateColor(color: string) {
    if (!this.instance) throw new Error('Instance not found');
    this.newValue = color;
    await this.dataService.updateFieldValue(this.instance?.uid, 'newValue', this.newValue);
    this.showUpdatingAlert();
  }

  private showUpdatingAlert() {
    if (!this.instance) return;
    this.alertService.showAlert('Updated ' + this.instance.uid + '...', BootstrapClass.INFO);
  }

  private parseVariable(type: VariableType, value: string) {
    switch (type) {
      case VariableType.String:
      case VariableType.Color:
        return value;
      case VariableType.Number:
        return parseFloat(value);
      case VariableType.Boolean:
        return value === 'true';
    }
  }
}
