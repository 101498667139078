import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HTTPRequestService } from '../data-management/HTTP-request.service';
import { DataService } from '../data-management/data.service';
import { SearchRequest } from '../../models/search/SearchRequest';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private readonly shown = new BehaviorSubject<boolean>(false);
  public readonly shown$ = this.shown.asObservable();

  constructor(
    private requestService: HTTPRequestService,
    private dataService: DataService,
  ) {}

  public show() {
    this.shown.next(true);
  }

  public hide() {
    this.shown.next(false);
  }

  public toggle() {
    if (this.shown.value) this.hide();
    else this.show();
  }

  public search(query: SearchRequest) {
    return this.requestService.search(this.dataService.currentGameId, this.dataService.currentDataPackage, query);
  }
}
