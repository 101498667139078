import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DataService } from '../../../_services/data-management/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { isStructType, StructType } from '../../../models/schema/StructType';
import { EnumType, isEnumType } from '../../../models/schema/EnumType';
import { isSelectType, SelectType } from '../../../models/schema/SelectType';
import { Field } from '../../../models/schema/Field';
import { HTTPRequestService } from '../../../_services/data-management/HTTP-request.service';
import { environment } from '../../../../environments/environment';
import { SelectTypeOption } from '../../../models/schema/SelectTypeOption';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resource } from '../../../models/data/Resource';
import { UnsavedChangesCheck } from '../../../_services/data-management/saved-changes-checker.guard';

@Component({
  selector: 'app-schema-table',
  templateUrl: './schema-table.component.html',
  styleUrls: ['./schema-table.component.scss'],
})
/**
 * Displays either a StructType, EnumType or SelectType from the schema list
 */
export class SchemaTableComponent implements OnInit, OnDestroy, UnsavedChangesCheck {
  table?: StructType | SelectType | EnumType; // The table Object
  tableHistory: StructType[] | SelectType[] | EnumType[] = []; // A local version history of the table Object
  tablePointer = 0; // A pointer that indicates the current version of the table
  savePointer = 0; // A pointer that indicates the pointer value of the last save
  openAccordionIndex: number | null = null; // Tracks which accordion tag is selected
  schemaTypes: string[] = []; // A list of all possible types in the schema
  filteredSchemaTypes: string[] = []; // A filtered list of all possible types
  structTypes: string[] = []; // A list of all StructTypes in the schema
  filteredStructsTypes: string[] = []; // A filtered list of all StructTypes
  selectedParentField: Field | undefined; // The selected parent-field in a migration modal
  selectedSubField: Field | undefined; // The selected sub-field in a migration modal
  type?: 'StructType' | 'EnumType' | 'SelectType'; // Type of Table (StructType, EnumType or SelectType)
  fieldEditorTypes: Resource[] = [
    { name: 'Default', value: '' },
    { name: 'Inline', value: 'Inline' },
    {
      name: 'SeamlessInline',
      value: 'SeamlessInline',
    },
    { name: 'Hidden', value: 'Hidden' },
    { name: 'Chat', value: 'Chat' },
    { name: 'CanvasEditor', value: 'CanvasEditor' },
    { name: 'ChatEditor', value: 'ChatEditor' },
  ]; // A list of all fieldEditorTypes

  protected readonly isStructType = isStructType;
  protected readonly isEnumType = isEnumType;
  protected readonly isSelectType = isSelectType;
  protected readonly Object = Object;
  private currentGameId = environment.defaultGame; // ID of current game
  private routeParamsSub?: Subscription; // Subscription to route parameters
  private typeId?: string; // TypeID of table

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpRequestService: HTTPRequestService,
    private alertService: AlertService,
    private titleService: Title,
    private modalService: NgbModal,
  ) {}

  @HostListener('window:beforeunload')
  hasNoUnsavedChanges() {
    return !this.hasUnsavedChanges();
  }

  /**
   * Initializes the component by setting up subscriptions to fetch schema data and to handle route parameters.
   */
  async ngOnInit() {
    this.loadSchemaData();
    this.handleRouteParams();
  }

  ngOnDestroy() {
    this.routeParamsSub?.unsubscribe();
  }

  /**
   * Loads the schema to map all possible schema types
   */
  loadSchemaData() {
    if (!this.httpRequestService || !this.httpRequestService.getSchema(this.currentGameId)) {
      return;
    }

    // Initialize schemaTypes through a schema subscription
    this.httpRequestService.getSchema(this.currentGameId).subscribe({
      next: (data) => {
        const structs = data.structTypes.flatMap((structType) => [`StructRef<${structType.typeId}>`, `Struct<${structType.typeId}>`]);
        const enums = data.enumTypes.flatMap((enumType) => [`EnumRef<${enumType.typeId}>`, `Enum<${enumType.typeId}>`]);
        const selects = data.selectTypes.flatMap((selectType) => `Select<${selectType.typeId}>`);
        const lists = [...structs, ...enums, ...selects].map((table) => `List<${table}>`);
        this.structTypes = data.structTypes.map((struct: StructType) => struct.typeId);
        this.schemaTypes.push(
          'string',
          'int',
          'bool',
          'float',
          'Vector2',
          'Vector3',
          'Color',
          'Icon',
          'ImageRef',
          'AudioRef',
          'VideoRef',
          'FileRef',
          ...structs,
          ...enums,
          ...selects,
          ...lists,
        );
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  /**
   * Initialize the table by fetching table data matching the route parameters
   */
  handleRouteParams() {
    this.routeParamsSub = this.activatedRoute.params.subscribe({
      next: async (params) => {
        this.typeId = params['typeId'];
        this.type = params['type'];

        if (this.typeId) {
          try {
            switch (this.type) {
              case 'StructType':
                this.table = await lastValueFrom(this.httpRequestService.getStructType(this.dataService.currentGameId, this.typeId));
                this.table.type = 'StructType';
                this.initEmptyFieldEditors();
                this.orderFields();
                break;

              case 'EnumType':
                this.table = await lastValueFrom(this.httpRequestService.getEnumType(this.dataService.currentGameId, this.typeId));
                this.table.type = 'EnumType';
                break;

              case 'SelectType':
                this.table = await lastValueFrom(this.httpRequestService.getSelectType(this.dataService.currentGameId, this.typeId));
                this.table.type = 'SelectType';
                break;

              default:
                console.warn(`Unknown type: ${this.type}`);
            }
          } catch (error) {
            this.handleError(error);
            return;
          }
          this.titleService.setTitle(
            `${environment.defaultGame.charAt(0).toUpperCase() + environment.defaultGame.slice(1)} - ${this.table?.name} - CAS`,
          );

          this.tableHistory = [];
          this.savePointer = 0;
          this.adjustDefaultValues();
          this.saveCurrentState();
        }
      },
      error: (error) => {
        this.handleError(error);
        return;
      },
    });
  }

  /**
   * Delete the table based on its type.
   */
  async deleteTable() {
    if (!this.table) return;

    // Multiple confirmation warnings
    if (!confirm(`Are you sure you want to delete ${this.table.typeId}?`)) return;

    if (
      !confirm(
        `Deleting ${this.table.typeId} also means deleting all instances (and sub objects) of ${this.table.typeId}. Are you still sure? Maybe you want to make a backup first?`,
      )
    )
      return;

    if (!confirm(`WARNING!\n\nYOU ARE ABOUT TO DELETE ALL INSTANCES OF ${this.table.typeId}`)) return;

    // Delete the table and user gets referred back to the schema-list
    try {
      if (isStructType(this.table)) {
        await lastValueFrom(this.httpRequestService.deleteStructType(this.dataService.currentGameId, this.table.typeId, false));
      } else if (isEnumType(this.table)) {
        await lastValueFrom(this.httpRequestService.deleteEnumType(this.dataService.currentGameId, this.table.typeId, false));
      } else if (isSelectType(this.table)) {
        await lastValueFrom(this.httpRequestService.deleteSelectType(this.dataService.currentGameId, this.table.typeId, false));
      }
      this.router.navigate(['/home/data-model']).then();
      this.alertService.showAlert(`Successfully deleted ${this.typeId}`, BootstrapClass.SUCCESS);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.error && error.error.status === 409 && !error.error.message.includes('unique')) {
        if (
          !confirm(
            `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of ${this.typeId}?`,
          )
        ) {
          return;
        }

        try {
          if (isStructType(this.table)) {
            await lastValueFrom(this.httpRequestService.deleteStructType(this.dataService.currentGameId, this.table.typeId, true));
          } else if (isEnumType(this.table)) {
            await lastValueFrom(this.httpRequestService.deleteEnumType(this.dataService.currentGameId, this.table.typeId, true));
          } else if (isSelectType(this.table)) {
            await lastValueFrom(this.httpRequestService.deleteSelectType(this.dataService.currentGameId, this.table.typeId, true));
          }
          this.router.navigate(['/home/data-model']).then();
          this.alertService.showAlert(`Successfully deleted ${this.typeId}`, BootstrapClass.SUCCESS);
        } catch (e) {
          this.handleError(e);
          return;
        }
      } else {
        this.handleError(error);
      }
    }
  }

  /**
   * Update the table based on its type.
   */
  async updateTable() {
    if (!this.typeId || !this.table) {
      return;
    }
    // Adjust default values for potential null bool
    this.adjustDefaultValues();

    // Adjust empty fieldId in fieldEditors
    this.adjustFieldIdFromFieldEditors();

    // Update the table
    try {
      if (isStructType(this.table)) {
        this.table = await lastValueFrom(this.httpRequestService.updateStructType(this.dataService.currentGameId, this.table, this.typeId));
        this.table.type = 'StructType';
        this.orderFields();
        this.table.fieldMigrations = [];
      } else if (isEnumType(this.table)) {
        this.table = await lastValueFrom(this.httpRequestService.updateEnumType(this.dataService.currentGameId, this.table, this.typeId));
        this.table.type = 'EnumType';
      } else if (isSelectType(this.table)) {
        this.table = await lastValueFrom(this.httpRequestService.updateSelectType(this.dataService.currentGameId, this.table, this.typeId));
        this.table.type = 'SelectType';
      }
    } catch (error) {
      // If table is a structType, we give the user the option to delete all instances of the Struct in order to update it
      if (
        isStructType(this.table) &&
        error instanceof HttpErrorResponse &&
        error.error &&
        error.error.status === 409 &&
        !error.error.message.includes('unique')
      ) {
        if (
          !confirm(
            `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of this field?`,
          )
        ) {
          return;
        }
        if (!confirm(`Are you sure you want to delete all values of this field?`)) {
          return;
        }
        try {
          this.table = await lastValueFrom(
            this.httpRequestService.updateStructType(this.dataService.currentGameId, this.table, this.typeId, true),
          );
          this.table.type = 'StructType';
          this.orderFields();
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else if (isEnumType(this.table) && error instanceof HttpErrorResponse && error.error && error.error.status === 409) {
        if (
          !confirm(`${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete the option anyway?`)
        ) {
          return;
        }
        try {
          this.table = await lastValueFrom(
            this.httpRequestService.updateEnumType(this.dataService.currentGameId, this.table, this.typeId, true),
          );
          this.table.type = 'EnumType';
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else {
        this.handleError(error);
        return;
      }
    }

    if (this.typeId != this.table.typeId) {
      this.router.navigate([`/home/data-model/${this.table.type}/${this.table.typeId}`]).then();
    }

    this.alertService.showAlert(`Successfully saved ${this.typeId}`, BootstrapClass.SUCCESS);

    // Adjust default values again for template
    this.adjustDefaultValues();
    this.savePointer = this.tablePointer;

    // todo: Not optimal to fetch the whole schema every time you save, but rewrite of dataservice.ts is necessary in order to fix this
    //       if you delete this line, the isResource value of a StructType is not automatically updated on save.
    await this.dataService.loadResources();
  }

  /**
   * Add an item to the table based on its type.
   */
  addItem() {
    if (isStructType(this.table) && this.type == 'StructType' && this.typeId) {
      const newField: Field = {
        fieldId: '',
        name: '',
        type: '',
        defaultValue: '',
        description: '',
        required: true,
        fieldEditor: {
          fieldId: '',
          structId: this.typeId,
          position: this.table.fields.length,
          showIf: '',
          showResource: false,
          editorType: '',
          scope: undefined,
          displayField: undefined,
        },
      };
      this.table.fields.push(newField);
    } else if (isEnumType(this.table) && this.type == 'EnumType') {
      const newOption = '';
      this.table.options.push(newOption);
    } else if (isSelectType(this.table) && this.type == 'SelectType') {
      const newOption: SelectTypeOption = {
        optionId: '',
        label: '',
      };
      this.table.options.push(newOption);
    }

    this.saveCurrentState();
  }

  /**
   * Remove an item from the table based on its type.
   * @param identifierToRemove The ID of the item to remove.
   */
  removeItem(identifierToRemove: string) {
    if (isStructType(this.table) && this.type == 'StructType') {
      this.table.fields = this.table.fields.filter((field) => field.fieldId !== identifierToRemove);
      this.updateFieldPositions();
    } else if (isEnumType(this.table) && this.type == 'EnumType') {
      this.table.options = this.table.options.filter((option) => option !== identifierToRemove);
    } else if (isSelectType(this.table) && this.type == 'SelectType') {
      this.table.options = this.table.options.filter((option) => (option as SelectTypeOption).optionId !== identifierToRemove);
    }

    this.saveCurrentState();
  }

  /**
   * Copies the current table to the clipboard.
   */
  copyTable() {
    const tableJSON = JSON.stringify(this.table);

    navigator.clipboard
      .writeText(tableJSON)
      .then(() => {
        this.alertService.showAlert('Copied to clipboard successfully!', BootstrapClass.SUCCESS);
        console.log('JSON string copied to clipboard successfully!');
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Checks if a StructType exists based on a field type reference
   * todo: This function should also return true if the reference is an existing SelectType or EnumType
   * @param typeReference
   */
  isExistingStruct(typeReference: string) {
    const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

    return (
      this.structTypes.includes(typeReference) ||
      this.structTypes.includes(
        typeReference.replace(regex, (match, typePart, refOrSelect, fieldPart) => {
          return fieldPart;
        }),
      )
    );
  }

  /**
   * Links the user to a different table
   * @param type The type to visit
   */
  visitTable(type: string) {
    // If the current table is an EnumType, the link to navigate is easy
    if (isEnumType(this.table)) {
      this.router.navigate([`home/data-model/StructType/${type}`]).then();
    }

    // If the current table is a StructType, we first have to extract the typeId
    else if (isStructType(this.table)) {
      const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

      const navigatePath = type.replace(regex, (match, typePart, refOrSelect, fieldPart) => {
        const replacedTypePart = typePart.endsWith('Ref') ? typePart.replace('Ref', 'Type') : `${typePart}Type`;
        return `${replacedTypePart}/${fieldPart}`;
      });

      this.router.navigate([`/home/data-model/${navigatePath}`]).then();
    }

    this.openAccordionIndex = null;
  }

  /**
   * Updates all Field positions of the table.
   */
  updateFieldPositions() {
    // Return if table isn't a StructType
    if (!isStructType(this.table)) {
      return;
    }

    // Set the position for each Field based on its current index
    for (const field of this.table.fields) {
      const index: number = this.table.fields.indexOf(field);
      if (field.fieldEditor) {
        // Explicitly checking before access
        field.fieldEditor.position = index;
      }
    }
  }

  /**
   * Initializes FieldEditors for Fields that don't have a FieldEditor yet.
   */
  initEmptyFieldEditors() {
    // Return if table isn't a StructType
    if (!isStructType(this.table)) {
      return;
    }

    for (const field of this.table.fields) {
      const index: number = this.table.fields.indexOf(field);
      if (!field.fieldEditor && this.typeId) {
        field.fieldEditor = {
          editorType: '',
          fieldId: '',
          position: index,
          showIf: undefined,
          showResource: false,
          structId: this.typeId,
          scope: undefined,
          displayField: undefined,
        };
      }
    }
  }

  /**
   * Handles a CdkDragDrop event
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    // Return if table isn't a StructType
    if (!isStructType(this.table)) {
      return;
    }

    moveItemInArray(this.table.fields, event.previousIndex, event.currentIndex);
    this.updateFieldPositions();
    this.saveCurrentState();
  }

  /**
   * Tracks accordion index
   * @param index
   */
  trackByFn(index: number): number {
    return index;
  }

  /**
   * Toggles the accordion index
   * @param index
   */
  toggleAccordion(index: number): void {
    this.openAccordionIndex = this.openAccordionIndex === index ? null : index;
  }

  /**
   * Orders all Fields based on their position value
   */
  orderFields() {
    if (!isStructType(this.table)) {
      return;
    }

    if (this.table.fields.some((field: Field) => field.fieldEditor)) {
      this.table.fields.sort((a: Field, b: Field) => {
        // Provide a default position value when fieldEditor is undefined
        const positionA = a.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        const positionB = b.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
        return positionA - positionB;
      });
    }
  }

  /**
   * Filters types based on user input
   * @param input
   */
  filterTypes(input: string) {
    this.filteredSchemaTypes = this.schemaTypes.filter((type: string) => type.toLowerCase().includes(input.toLowerCase()));
  }

  /**
   * Filters StructTypes based on user input
   * @param input
   */
  filterStructs(input: string) {
    return (this.filteredStructsTypes = this.structTypes.filter((type: string) => type.toLowerCase().includes(input.toLowerCase())).sort());
  }

  /**
   * Gets all options of a certain EnumType
   * @param type
   */
  getEnumOptions(type: string) {
    const regex = /Enum<([^>]+)>/;
    const match = type.match(regex);
    if (match) {
      const enumType = this.dataService.getEnumType(match[1]);
      return ['', ...enumType.options];
    }
    return undefined;
  }

  /**
   * Gets all options of a certain SelectType
   * @param type
   */
  getSelectOptions(type: string) {
    const regex = /Select<([^>]+)>/;
    const match = type.match(regex);
    if (match) {
      const selectType = this.dataService.getSelectType(match[1]);
      return ['', ...selectType.options.map((option) => option.optionId)];
    }
    return undefined;
  }

  /**
   * Saves the current state of the table to the local version history
   */
  saveCurrentState() {
    // Timeout so save happens after model change
    setTimeout(() => {
      this.tableHistory = this.tableHistory.slice(0, this.tablePointer + 1);
      this.tableHistory.push(JSON.parse(JSON.stringify(this.table)));
      this.tablePointer = this.tableHistory.length - 1;
    }, 0);
  }

  /**
   * Undoes last change
   */
  undo() {
    if (this.canUndo()) {
      this.tablePointer--;
      this.table = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Redoes last change
   */
  redo() {
    if (this.canRedo()) {
      this.tablePointer++;
      this.table = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Checks if user can Undo a change
   */
  canUndo() {
    return this.tablePointer > 0;
  }

  /**
   * Checks if user can Redo a change
   */
  canRedo() {
    return this.tablePointer < this.tableHistory.length - 1;
  }

  /**
   * todo: WIP, this function can later be used for an unsavedChanges guard
   */
  hasUnsavedChanges() {
    return this.savePointer !== this.tablePointer;
  }

  /**
   * Adjust default values for edge cases
   */
  adjustDefaultValues() {
    if (!isStructType(this.table)) {
      return;
    }

    for (const field of this.table.fields) {
      // Adjust default values of booleans that have a value other than true or false
      if (field.type === 'bool') {
        field.defaultValue ? (field.defaultValue = `${field.defaultValue}`.toLowerCase() === 'true') : (field.defaultValue = false);
      }
      // Adjust default values of Lists
      else if (field.type.startsWith('List')) {
        field.defaultValue = '[]';
      }
      // Adjust default values of non-required fields, Structs and StructRefs
      else if (
        !field.required ||
        field.type === 'ImageRef' ||
        field.type === 'AudioRef' ||
        field.type === 'VideoRef' ||
        field.type === 'FileRef' ||
        // field.type === 'Icon' ||
        field.type.startsWith('Struct')
      ) {
        field.defaultValue = undefined;
      }
    }
  }

  /**
   * Adjusts empty fieldId's in FieldEditors
   */
  adjustFieldIdFromFieldEditors() {
    if (!isStructType(this.table)) {
      return;
    }
    for (const field of this.table.fields) {
      if (field.fieldEditor) {
        field.fieldEditor.fieldId = field.fieldId;
      }
    }
  }

  /**
   * Checks if field ID or field Type input should be disabled based on if it existed at the savePointer
   * @param field
   */
  canEditFieldIdAndType(field: Field) {
    //todo: when you do a normal migration to change the name of a fieldId, this check fails
    // also fails when creating a new field that has the same fieldId as another field

    const entry = this.tableHistory[this.savePointer];
    return isStructType(entry) && !entry.fields.some((f: Field) => f.fieldId === field.fieldId);
  }

  /**
   * Returns an array of all fields with type StructRef
   */
  getFieldsWithTypeStruct() {
    if (!isStructType(this.table)) {
      return [];
    }

    return this.table.fields.filter((field: Field) => field.type.startsWith('Struct'));
  }

  /**
   * Returns all fields of a subStruct
   * @param fieldId
   */
  getFieldsFromSubStruct(fieldId: string) {
    if (!isStructType(this.table)) {
      return [];
    }

    const type = this.table.fields.find((field) => field.fieldId === fieldId)?.type;
    if (!type) {
      return [];
    }

    const regex = /Struct(?:Ref)?<([^>]+)>/;
    const match = type.match(regex);
    if (match && !type.startsWith('List')) {
      return this.dataService.getStructType(match[1]).fields;
    }
    return [];
  }

  /**
   * Creates a fieldMigration entry
   * @param existingFieldId
   * @param newFieldId
   */
  migrateField(existingFieldId: string, newFieldId: string) {
    if (!isStructType(this.table)) {
      return;
    }
    if (!this.table.fieldMigrations) {
      this.table.fieldMigrations = [];
    }

    this.table.fieldMigrations.push({
      existingFieldId: existingFieldId,
      newFieldId: newFieldId,
    });

    const existingField = this.table.fields.find((field) => field.fieldId == existingFieldId);
    if (existingField) {
      existingField.fieldId = newFieldId;
      existingField.name = newFieldId.charAt(0).toUpperCase() + newFieldId.slice(1);
    }
    this.saveCurrentState();
  }

  /**
   * Creates a fieldMigration to subStruct entry
   * @param parentField
   * @param subField
   * @param existingField
   */
  migrateFieldToSubStruct(parentField: Field, subField: Field, existingField: Field) {
    if (!isStructType(this.table)) {
      return;
    }

    if (subField.type != existingField.type) {
      if (
        !confirm(
          `Subtype field (${subField.type}) does not match existing field (${existingField.type}).

Do you still want to continue?`,
        )
      ) {
        return;
      }
    }
    if (!this.table.fieldMigrations) {
      this.table.fieldMigrations = [];
    }

    if (existingField.type) {
      this.table.fieldMigrations.push({
        existingFieldId: existingField.fieldId,
        newFieldId: `${parentField.fieldId}.${subField.fieldId}`,
      });
    }

    if (parentField !== existingField) {
      this.removeItem(existingField.fieldId);
    }
    this.saveCurrentState();
  }

  /**
   * Creates a fieldMigration from subStruct entry
   * todo: WIP, don't know if the backend even supports this yet..
   * @param existingSubField
   * @param newField
   * @param existingField
   */
  migrateFieldFromSubStruct(existingSubField: Field, newField: Field, existingField: Field) {
    if (!isStructType(this.table)) {
      return;
    }

    if (existingSubField.type != newField.type) {
      if (
        !confirm(
          `Subtype field (${existingSubField.type}) does not match existing field (${existingField.type}).

Do you still want to continue?`,
        )
      ) {
        return;
      }
    }
    if (!this.table.fieldMigrations) {
      this.table.fieldMigrations = [];
    }

    if (existingSubField.type) {
      this.table.fieldMigrations.push({
        existingFieldId: `${existingField.fieldId}.${existingSubField}`,
        newFieldId: newField.fieldId,
      });
    }
    this.saveCurrentState();
  }

  /**
   * Opens a modal
   * @param content
   */
  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title' }).result.then();
  }

  /**
   * Handles any error
   * @param error
   */
  handleError(error: unknown) {
    console.error('An error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        alert('No Internet Connection\n\nPlease check your network connection.');
        this.alertService.showAlert('No Internet Connection', BootstrapClass.DANGER);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        alert(`Server Error: ${error.status}\n\n${error.error.message}`);
        this.alertService.showAlert('Server Error', BootstrapClass.DANGER);
      }
    } else if (error instanceof TypeError) {
      // Handle client-side or network error
      alert('Error: A network error occurred\n\nPlease try again later.');
      this.alertService.showAlert('Error: A network error occurred', BootstrapClass.DANGER);
    } else if (error instanceof SyntaxError) {
      // Handle Syntax Errors
      alert(`Syntax Error: \n\n${error.message}`);
      this.alertService.showAlert('Syntax Error', BootstrapClass.DANGER);
    } else if (error instanceof Error) {
      // Handle generic error conditions
      alert(`Error\n\n${error.message}`);
      this.alertService.showAlert('Error', BootstrapClass.DANGER);
    } else {
      // Handle unknown errors
      alert('Unknown Error\n\nAn unknown error occurred. Please contact support.');
      this.alertService.showAlert('Unknown Error', BootstrapClass.DANGER);
    }
  }

  /**
   * Handles a keyboardEvent
   * @param event
   */
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault();
      this.undo();
    } else if (event.ctrlKey && event.key === 'y') {
      event.preventDefault();
      this.redo();
    } else if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      this.updateTable().then();
    }
  }
}
