import { Component } from '@angular/core';
import { HTTPRequestService } from '../../_services/data-management/HTTP-request.service';
import { lastValueFrom, Observable } from 'rxjs';
import { DataCleanupAnalyzeResult } from '../../models/maintenance/DataCleanupAnalyzeResult';
import { DataService } from '../../_services/data-management/data.service';
import { DataCleanupAction } from '../../models/maintenance/DataCleanupAction';
import { DataCleanupAnalyzeResultItem } from '../../models/maintenance/DataCleanupAnalyzeResultItem';
import { DataCleanupResponse } from '../../models/maintenance/DataCleanupResponse';
import { DataCleanupActionRequest } from '../../models/maintenance/DataCleanupActionRequest';
import { AlertService } from '../../_services/UI-elements/alert-service';
import { NavigationService } from '../../_services/navigation.service';
import { replaceBackticksWithElement } from '../../_services/utils';

@Component({
  selector: 'app-maintenance-screen',
  templateUrl: './maintenance-screen.component.html',
  styleUrls: ['./maintenance-screen.component.scss'],
})
export class MaintenanceScreenComponent {
  protected selectedCleanupActions: Record<string, boolean> = {};
  protected selectedCleanupActionsCount = 0;

  protected $analyzeRequest?: Observable<DataCleanupAnalyzeResult>;
  protected analyzedData?: DataCleanupAnalyzeResult;

  protected $cleanupRequest?: Observable<DataCleanupResponse>;
  protected cleanupResponse?: DataCleanupResponse;

  protected dataTypes?: Record<string, number>;
  protected suggestedActions?: Record<DataCleanupAction, number>;

  protected readonly DataCleanupAction = DataCleanupAction;
  protected readonly Object = Object;

  protected $clearCacheRequest?: Observable<void>;

  constructor(
    private requestService: HTTPRequestService,
    private dataService: DataService,
    private alertService: AlertService,
    private navigationService: NavigationService,
  ) {
    if (this.analyzedData?.items) this.setCleanupActions(this.analyzedData.items);
  }

  runAnalyzeData() {
    this.$cleanupRequest = undefined;
    this.cleanupResponse = undefined;

    this.analyzedData = undefined;
    this.$analyzeRequest = this.requestService.analyzeMaintenanceTasks(this.dataService.currentGameId, this.dataService.currentDataPackage);

    const subscription = this.$analyzeRequest.subscribe((result) => {
      this.analyzedData = {
        ...result,
        items: result.items.map((item) => ({
          ...item,
          description: replaceBackticksWithElement(item.description, 'code'),
        })),
      };

      this.dataTypes = result.items.reduce(
        (acc, item) => {
          acc[item.dataType] = (acc[item.dataType] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>,
      );

      this.suggestedActions = result.items.reduce(
        (acc, item) => {
          acc[item.action] = (acc[item.action] || 0) + 1;
          return acc;
        },
        {} as Record<DataCleanupAction, number>,
      );

      this.setCleanupActions(result.items);
      this.$analyzeRequest = undefined;
      subscription.unsubscribe();
    });
  }

  runSelectedActions() {
    if (!this.selectedCleanupActionsCount || !this.analyzedData) return;

    const confirmation = confirm(
      `Are you sure you want all ${this.selectedCleanupActionsCount} action(s)? This action is destructive and cannot be undone.`,
    );
    if (!confirmation) return;

    const actions = Object.entries(this.selectedCleanupActions)
      .filter(([, selected]) => selected)
      .map(([dataInstanceUid]) => {
        const item = this.analyzedData?.items.find((item) => item.dataInstanceUid + ':' + item.fieldValue === dataInstanceUid);
        if (!item) throw new Error(`Could not find action for ${dataInstanceUid}`);

        return {
          dataInstanceUid,
          action: item.action,
          fieldId: item.fieldId,
          fieldValue: item.fieldValue,
        } satisfies DataCleanupActionRequest;
      });

    this.$cleanupRequest = this.requestService.performDataCleanupActions(
      this.dataService.currentGameId,
      this.dataService.currentDataPackage,
      actions,
    );

    this.analyzedData = undefined;
    this.$analyzeRequest = undefined;

    this.selectedCleanupActions = {};
    this.selectedCleanupActionsCount = 0;

    const subscription = this.$cleanupRequest.subscribe((result) => {
      this.cleanupResponse = result;
      subscription.unsubscribe();
      this.$cleanupRequest = undefined;
    });
  }

  updateSelectionCount() {
    this.selectedCleanupActionsCount = Object.values(this.selectedCleanupActions).filter(Boolean).length;
  }

  async clearCache() {
    this.$clearCacheRequest = this.requestService.clearCache();
    try {
      await lastValueFrom(this.$clearCacheRequest);
      this.alertService.success('Cache cleared');
    } catch (e) {
      this.alertService.error('Failed to clear cache');
    } finally {
      this.$clearCacheRequest = undefined;
    }
  }

  async clickItem(resultItem: DataCleanupAnalyzeResultItem) {
    if (resultItem.action !== DataCleanupAction.MANUAL_FIX) {
      const id = resultItem.dataInstanceUid + ':' + resultItem.fieldValue;
      this.selectedCleanupActions[id] = !this.selectedCleanupActions[id];
      return;
    }

    const [segments] = await this.navigationService.findDataInstanceUrl(resultItem.dataInstanceUid);
    window.open(segments.join('/'));
  }

  private setCleanupActions(items: DataCleanupAnalyzeResultItem[]) {
    this.selectedCleanupActions = items.reduce(
      (acc, item) => {
        acc[item.dataInstanceUid + ':' + item.fieldValue] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );

    this.updateSelectionCount();
  }
}
