import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, filter, lastValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { TokenManagerService } from './token-manager.service';
import { AuthResponse, HTTPRequestService } from '../data-management/HTTP-request.service';
import { fromIterable } from 'rxjs/internal/observable/innerFrom';

export interface ProfileSetup {
  password?: string;
  passwordConfirmation?: string;
  pin?: string;
  pinConfirmation?: string;
  contactType?: string;
  phone?: string;
  personalEmail?: string;
  termsAgreed?: boolean;
}

export class InvalidRoleError extends Error {
  constructor() {
    super('User is not content editor');
  }
}

export enum AuthState {
  /**
   * When auth is not yet initialized (state is not known yet)
   */
  INITIALIZING = 'INITIALIZING',
  /**
   * The user has completed the initial setup, but needs to log in (again)
   */
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  /**
   * The user is fully logged in
   */
  LOGGED_IN = 'LOGGED_IN',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public state: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(AuthState.INITIALIZING);

  constructor(
    private communicator: HTTPRequestService,
    private tokenManager: TokenManagerService,
  ) {
    this.initialize().then(() => {
      this.tokenManager
        .stream()
        .pipe(
          // null token means the token expired
          filter((token) => !token),
        )
        .subscribe(() => {
          // TODO Handle token expired (maybe refresh the token?)
        });
    });
  }

  isLoggedIn(): Observable<boolean> {
    return this.state.pipe(
      filter((s) => s !== AuthState.INITIALIZING),
      map((s) => s === AuthState.LOGGED_IN),
    );
  }

  login(email: string, password: string) {
    return this.communicator.login(email, password).pipe(map((result) => this.handleSuccessfulLogin(result)));
  }

  /**
   * Logs the user out.
   * Note that this method will never fail. If the logout fails in the backend, it will still clear the credentials, effectively logging the user out
   */
  logout() {
    // We make an outer observable here, so that we can clear the login after the logout request finishes. We don't care if it actually completes, we just need it to finish.
    return fromIterable([1]).pipe(
      // Having catchError in the inner observable pipeline will keep outer observable live [i.e. keep emitting the new value even if inner observable throws exception].
      switchMap(() =>
        this.communicator.logout().pipe(
          catchError((err) => {
            console.warn(`Swallowing error during logout: ${err?.message}`, err);
            return of(EMPTY);
          }),
        ),
      ),
      switchMap(async () => {
        await this.clearLogin();
      }),
    );
  }

  async clearLogin() {
    // We start with setting the state
    this.state.next(AuthState.NOT_LOGGED_IN);
    this.tokenManager.clearAccessToken();
  }

  private async initialize() {
    const accessToken = await this.tokenManager.getAccessToken();

    if (!accessToken) {
      this.state.next(AuthState.NOT_LOGGED_IN);
    } else {
      try {
        await this.initLoggedInUser();
        this.state.next(AuthState.LOGGED_IN);
        return;
      } catch (e) {
        this.state.next(AuthState.NOT_LOGGED_IN);
        throw e;
      }
    }
  }

  private async initLoggedInUser() {
    const isEditor = await lastValueFrom(this.communicator.isContentEditor());
    if (!isEditor) throw new InvalidRoleError();

    return true;
  }

  private async handleSuccessfulLogin(result: AuthResponse) {
    this.tokenManager.setAccessToken(result.accessToken);

    try {
      await this.initLoggedInUser();
      this.state.next(AuthState.LOGGED_IN);
    } catch (e) {
      this.state.next(AuthState.NOT_LOGGED_IN);
      throw e;
    }
  }
}
