import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../_services/data-management/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfirmationModalService } from '../../../_services/UI-elements/confirmation-modal.service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { LoadingScreenService } from '../../../_services/UI-elements/loading-screen.service';
import { AlertService } from '../../../_services/UI-elements/alert-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Field } from '../../../models/schema/Field';
import { DataInstance } from '../../../models/data/DataInstance';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, OnDestroy {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModal!: NgbModalRef;

  resources: DataInstance[] = [];
  fieldsToDisplay: Field[] = [];
  structTypeDescription = '';
  structType = '';
  routeSub?: Subscription;

  /**
   * If a resource was duplicated, this will be set to the new resource
   * @protected
   */
  protected newResource?: DataInstance;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private confirmService: ConfirmationModalService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      await this.dataService.waitForInit();

      this.routeSub = this.route.params.subscribe((params) => {
        if (params && params['resource']) {
          this.structType = params['resource'];
          this.loadingScreenService.show(() => this.getResources());
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  openResource(resourceUid: string): Promise<boolean> {
    return this.router.navigate(['/home', this.structType, resourceUid]);
  }

  createResource() {
    return this.loadingScreenService.show(async () => {
      const newResourceUid = (await this.dataService.initStruct(this.structType)).uid;
      await this.openResource(newResourceUid);
    });
  }

  resourceName(resourceUid: string): string | undefined {
    const instance = this.resources.find((r) => r.uid === resourceUid);
    if (!instance) return undefined;

    // If there is a field called name, we use that, otherwise which is the first display field
    const nameField = this.fieldsToDisplay.find((f) => f.name === 'name') ?? this.fieldsToDisplay[0];
    return instance.fieldValues.find((f) => f.field === nameField.fieldId)?.value as string | undefined;
  }

  getFieldDisplayValue(resource: DataInstance, field: Field): string | undefined {
    return resource.fieldValues.find((f) => f.field === field.fieldId)?.value as string | undefined;
  }

  /*
   * Delete a resource
   * @param resourceUid The resource's UID
   * @param force If set, the resource will be instantly deleted even if it is used in other places. References will be cleared
   */
  async deleteResource(resourceUid: string): Promise<void> {
    if (
      !(await firstValueFrom(
        this.confirmService.confirm(`Are you sure you want to delete resource: "${this.resourceName(resourceUid) ?? resourceUid}"?`),
      ))
    ) {
      return;
    }

    return (await this.loadingScreenService.show(async () => {
      try {
        const resource = this.resources.find((r) => r.uid === resourceUid);
        if (!resource) {
          // Shouldn't happen
          this.alertService.showAlert('Resource not found!', BootstrapClass.DANGER);
          return;
        }
        if (
          await this.dataService.deleteDataInstance(resource, {
            throwError: true,
          })
        ) {
          this.resources.splice(this.resources.indexOf(resource), 1);
        }
      } catch (e) {
        console.error('Error deleting resource: ', e);
      }
    })) as Promise<void>;
  }

  duplicateResource(resourceUid: string) {
    return this.loadingScreenService.show(async () => {
      try {
        const dataInstance = (await this.dataService.duplicateDataInstance([resourceUid])).dataInstances[0];
        this.resources.push(dataInstance);
        this.sortResources();

        this.newResource = dataInstance;

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModal, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate resource!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  copyResourceIdToClipboard(resourceUid: string) {
    navigator.clipboard
      .writeText(resourceUid)
      .then(() => {
        this.alertService.showAlert('Resource ID copied to clipboard: ' + resourceUid, BootstrapClass.SUCCESS);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy Resource ID!', BootstrapClass.DANGER);
      });
  }

  private sortResources() {
    this.resources.sort((a, b) => {
      const aName = this.resourceName(a.uid) ?? '';
      const bName = this.resourceName(b.uid) ?? '';
      // Empty strings should be sorted last
      if (!aName && !bName) return 0;
      if (!aName) return 1;
      if (!bName) return -1;
      return aName.localeCompare(bName);
    });
  }

  private async getResources() {
    await this.dataService.waitForInit();
    const structType = this.dataService.getStructType(this.structType);
    this.fieldsToDisplay = structType.fields
      .filter((field) => field.fieldEditor && field.fieldEditor.showResource)
      .sort((a, b) => {
        if (a.fieldEditor && b.fieldEditor) {
          return a.fieldEditor.position - b.fieldEditor.position;
        } else {
          return 0;
        }
      });

    this.resources = await this.dataService.getDataInstancesPerStructType(this.structType);
    this.sortResources();

    this.structTypeDescription = structType.description;
  }
}
